import get from 'lodash/fp/get';

import { createSelector } from 'reselect';

import { getProductionState } from '../selectors';

export const getQualityTestsBatches = createSelector(
  getProductionState,
  get('microhold.quality_tests')
);
export const getQualityTestsPages = createSelector(getProductionState, get('microhold.pages'));

export const getMicroholdPages = createSelector(getProductionState, get('microhold.pages'));

export const getPallets = createSelector(getProductionState, state => state?.microhold?.pallets);

export const getScannedPallet = createSelector(
  getProductionState,
  state => state?.microhold?.pallet
);

export const getBoxContainsQualityTestBatch = createSelector(
  getProductionState,
  get('details.box.contains_qualitytest_batch')
);
