import initApiClient from 'services/ApiClient';

import exponentialBackoffThreeTimes from 'utils/retryPolicies';
import logSentryError from 'utils/sentry';

import {
  FEATURE_FLAGS_FETCH_FAIL,
  FEATURE_FLAGS_FETCH_START,
  FEATURE_FLAGS_FETCH_SUCCESS,
} from './actionTypes';

export const fetchFeatureFlags = () => async dispatch => {
  const APIClient = initApiClient(dispatch);
  dispatch({ type: FEATURE_FLAGS_FETCH_START });
  try {
    const response = await exponentialBackoffThreeTimes(() => APIClient.get('/flags'));
    const fetchedFeatureFlags = await response.json();
    return dispatch({ type: FEATURE_FLAGS_FETCH_SUCCESS, fetchedFeatureFlags });
  } catch (error) {
    logSentryError('[Flipt]', error, { logLevel: 'info' });
    return dispatch({ type: FEATURE_FLAGS_FETCH_FAIL, error });
  }
};
