import { matchPath as defaultMatchPath } from 'react-router-dom';

import curry from 'lodash/fp/curry';
import endsWith from 'lodash/fp/endsWith';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';
import _ from 'lodash/fp/placeholder';

import { createSelector } from 'reselect';

import { getViewMode } from 'dux/settings/selectors';

import { getBoxesNotRefunded } from '../orders/refundsSelectors';
import { getOrderByPubkey } from '../orders/selectors';

const matchPath = curry(defaultMatchPath);

const getRouterState = get('router');

export const getLocation = createSelector(getRouterState, get('location'));
export const getPathname = createSelector(getLocation, get('pathname'));
export const getLocationSearch = createSelector(getLocation, get('search'));

export const getOrderPubkeyFromOrderDetailPath = createSelector(
  getPathname,
  flow(matchPath('/orders/:pubkey/*', _), get('params.pubkey'))
);

export const getTeammatePubkeyFromTeammatePath = createSelector(
  getPathname,
  flow(matchPath('/teammates/:pubkey/*', _), get('params.pubkey'))
);

export const getCustomerPubkeyFromCustomerDetailPath = createSelector(
  getPathname,
  flow(matchPath('/customers/:pubkey/*', _), get('params.pubkey'))
);

export const getBoxPubkeyFromProductionDetailPath = createSelector(
  getPathname,
  flow(matchPath('/production/:location/:pubkey', _), get('params.pubkey'))
);

export const getSubscriptionPubkeyFromSubscriptionDetailPath = createSelector(
  getPathname,
  flow(matchPath('/subscriptions/:pubkey/*', _), get('params.pubkey'))
);

export const getSurveyPubkeyFromSurveyDetailPath = createSelector(
  getPathname,
  flow(matchPath('/surveys/:pubkey', _), get('params.pubkey'))
);

export const getNextFromLocationSearchString = createSelector(getLocationSearch, search =>
  new URLSearchParams(search).get('next')
);

export const getActionFromUrl = createSelector(getLocationSearch, search =>
  new URLSearchParams(search).get('action')
);

export const getOrderFromOrderDetailPath = createSelector(
  [state => state, getOrderPubkeyFromOrderDetailPath],
  (state, orderPubkey) => getOrderByPubkey(state, { orderPubkey })
);

export const getOrderStatusFromOrderDetailPath = createSelector(
  getOrderFromOrderDetailPath,
  get('status')
);

export const getOrderStatusesFromOrderDetailPath = createSelector(
  [state => state, getOrderPubkeyFromOrderDetailPath],
  (state, orderPubkey) => get('statuses', getOrderByPubkey(state, { orderPubkey }))
);

export const getBoxesNotRefundedFromOrderDetailPath = createSelector(
  [state => state, getOrderPubkeyFromOrderDetailPath],
  (state, orderPubkey) => getBoxesNotRefunded(state, { orderPubkey })
);

export const getCustomerPubkeyFromSubscriptionDetailPath = createSelector(
  getSubscriptionPubkeyFromSubscriptionDetailPath,
  get('customer.pubkey')
);

export const isOrderPage = createSelector(
  getPathname,
  pathname => includes('orders', pathname) && endsWith('formulas', pathname)
);

export const getLanePubkeyByLaneName = createSelector(
  getLocation,
  getViewMode,
  (location, viewMode) => {
    const params = new URLSearchParams(location.search);
    return viewMode === 'buffer-space' ? 'buffer-space' : params.get('lane');
  }
);
