import { status as getAuthStatus } from '../auth/selectors';
import { getLoading as getTeammatesIsLoading } from '../teammates/selectors';
import { fetchFeatureFlags } from './actions';

const fetchFeatureFlagsOnAuthSuccess = store => next => action => {
  const wasAuthenticatingWithAuth = getAuthStatus(store.getState());
  const wasAuthenticatingWithTeammates = getTeammatesIsLoading(store.getState());
  const result = next(action);
  const isDoneAuthenticatingWithAuth = getAuthStatus(store.getState());
  const isDoneAuthenticatingWithTeammates = !getTeammatesIsLoading(store.getState());

  if (
    (wasAuthenticatingWithAuth === 'pending' && isDoneAuthenticatingWithAuth === 'fulfilled') ||
    (wasAuthenticatingWithTeammates && isDoneAuthenticatingWithTeammates)
  ) {
    fetchFeatureFlags()(store.dispatch, store.getState);
  }
  return result;
};

export default fetchFeatureFlagsOnAuthSuccess;
