export const CUSTOMERS_FETCH_START = 'CUSTOMERS_FETCH_START';
export const CUSTOMERS_FETCH_SUCCESS = 'CUSTOMERS_FETCH_SUCCESS';
export const CUSTOMERS_FETCH_ERROR = 'CUSTOMERS_FETCH_ERROR';

export const CUSTOMER_FETCH_START = 'CUSTOMER_FETCH_START';
export const CUSTOMER_FETCH_SUCCESS = 'CUSTOMER_FETCH_SUCCESS';
export const CUSTOMER_FETCH_ERROR = 'CUSTOMER_FETCH_ERROR';

export const CUSTOMER_CART_FETCH_START = 'CUSTOMER_CART_FETCH_START';
export const CUSTOMER_CART_FETCH_SUCCESS = 'CUSTOMER_CART_FETCH_SUCCESS';
export const CUSTOMER_CART_FETCH_ERROR = 'CUSTOMER_CART_FETCH_ERROR';

export const CUSTOMER_FORMULAS_FETCH_START = 'CUSTOMER_FORMULAS_FETCH_START';
export const CUSTOMER_FORMULAS_FETCH_SUCCESS = 'CUSTOMER_FORMULAS_FETCH_SUCCESS';
export const CUSTOMER_FORMULAS_FETCH_ERROR = 'CUSTOMER_FORMULAS_FETCH_ERROR';

export const CUSTOMER_CREATE_START = 'CUSTOMER_CREATE_START';
export const CUSTOMER_CREATE_SUCCESS = 'CUSTOMER_CREATE_SUCCESS';

export const CUSTOMER_UPDATE_START = 'CUSTOMER_UPDATE_START';
export const CUSTOMER_UPDATE_SUCCESS = 'CUSTOMER_UPDATE_SUCCESS';

export const CUSTOMER_DELETE_START = 'CUSTOMER_DELETE_START';
export const CUSTOMER_DELETE_SUCCESS = 'CUSTOMER_DELETE_SUCCESS';
export const CUSTOMER_DELETE_ERROR = 'CUSTOMER_DELETE_ERROR';

export const CUSTOMER_SUBSCRIPTIONS_FETCH_START = 'CUSTOMER_SUBSCRIPTIONS_FETCH_START';
export const CUSTOMER_SUBSCRIPTIONS_FETCH_SUCCESS = 'CUSTOMER_SUBSCRIPTIONS_FETCH_SUCCESS';
export const CUSTOMER_SUBSCRIPTIONS_FETCH_ERROR = 'CUSTOMER_SUBSCRIPTIONS_FETCH_ERROR';

export const CUSTOMER_ORDERS_FETCH_START = 'CUSTOMER_ORDERS_FETCH_START';
export const CUSTOMER_ORDERS_FETCH_SUCCESS = 'CUSTOMER_ORDERS_FETCH_SUCCESS';
export const CUSTOMER_ORDERS_FETCH_ERROR = 'CUSTOMER_ORDERS_FETCH_ERROR';

export const CUSTOMER_PROFILES_FETCH_START = 'CUSTOMER_PROFILES_FETCH_START';
export const CUSTOMER_PROFILES_FETCH_SUCCESS = 'CUSTOMER_PROFILES_FETCH_SUCCESS';
export const CUSTOMER_PROFILES_FETCH_ERROR = 'CUSTOMER_PROFILES_FETCH_ERROR';

export const CUSTOMER_SELFIES_FETCH_START = 'CUSTOMER_SELFIES_FETCH_START';
export const CUSTOMER_SELFIES_FETCH_SUCCESS = 'CUSTOMER_SELFIES_FETCH_SUCCESS';
export const CUSTOMER_SELFIES_FETCH_ERROR = 'CUSTOMER_SELFIES_FETCH_ERROR';

export const CUSTOMER_ERROR_DETECTION_START = 'CUSTOMER_ERROR_DETECTION_START';
export const CUSTOMER_ERROR_DETECTION_SUCCESS = 'CUSTOMER_ERROR_DETECTION_SUCCESS';
export const CUSTOMER_ERROR_DETECTION_ERROR = 'CUSTOMER_ERROR_DETECTION_ERROR';

export const CUSTOMER_ADDRESS_CREATE_START = 'CUSTOMER_ADDRESS_CREATE_START';
export const CUSTOMER_ADDRESS_CREATE_SUCCESS = 'CUSTOMER_ADDRESS_CREATE_SUCCESS';
export const CUSTOMER_ADDRESS_CREATE_ERROR = 'CUSTOMER_ADDRESS_CREATE_ERROR';

export const CUSTOMER_ADDRESS_EDIT_START = 'CUSTOMER_ADDRESS_EDIT_START';
export const CUSTOMER_ADDRESS_EDIT_SUCCESS = 'CUSTOMER_ADDRESS_EDIT_SUCCESS';
export const CUSTOMER_ADDRESS_EDIT_ERROR = 'CUSTOMER_ADDRESS_EDIT_ERROR';

export const CUSTOMER_ADDRESS_DELETE_START = 'CUSTOMER_ADDRESS_DELETE_START';
export const CUSTOMER_ADDRESS_DELETE_SUCCESS = 'CUSTOMER_ADDRESS_DELETE_SUCCESS';
export const CUSTOMER_ADDRESS_DELETE_ERROR = 'CUSTOMER_ADDRESS_DELETE_ERROR';

export const CUSTOMER_COUPONS_FETCH_START = 'CUSTOMER_COUPONS_FETCH_START';
export const CUSTOMER_COUPONS_FETCH_SUCCESS = 'CUSTOMER_COUPONS_FETCH_SUCCESS';
export const CUSTOMER_COUPONS_FETCH_ERROR = 'CUSTOMER_COUPONS_FETCH_ERROR';

export const CUSTOMER_SURVEYS_FETCH_START = 'CUSTOMER_SURVEYS_FETCH_START';
export const CUSTOMER_SURVEYS_FETCH_SUCCESS = 'CUSTOMER_SURVEYS_FETCH_SUCCESS';
export const CUSTOMER_SURVEYS_FETCH_ERROR = 'CUSTOMER_SURVEYS_FETCH_ERROR';

export const CUSTOMER_STYLISTS_FETCH_START = 'CUSTOMER_STYLISTS_FETCH_START';
export const CUSTOMER_STYLISTS_FETCH_SUCCESS = 'CUSTOMER_STYLISTS_FETCH_SUCCESS';
export const CUSTOMER_STYLISTS_FETCH_ERROR = 'CUSTOMER_STYLISTS_FETCH_ERROR';
