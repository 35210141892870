import isEmpty from 'lodash/fp/isEmpty';

import initApiClient from 'services/ApiClient';

import { prodItemsBulkUpdate } from 'utils/promiseProditemUpdate';
import logSentryError from 'utils/sentry';

import { getActionFromUrl, getBoxPubkeyFromProductionDetailPath } from '../../router/selectors';
import {
  PRODUCTION_FETCH_NEXT_BOX_FROM_LANE_ERROR,
  PRODUCTION_FETCH_NEXT_BOX_FROM_LANE_START,
  PRODUCTION_FETCH_NEXT_BOX_FROM_LANE_SUCCESS,
  PRODUCTION_PROD_ITEMS_UPDATE_NEXT_STATUS_ERROR,
  PRODUCTION_PROD_ITEMS_UPDATE_NEXT_STATUS_START,
  PRODUCTION_PROD_ITEMS_UPDATE_NEXT_STATUS_SUCCESS,
} from '../actionTypes';
import { getNextBoxInLane, getNextBoxStatus, getPendingAndOrProducedProdItems } from './selectors';

export const fetchNextBoxInLanePubkey = productionLanePubKey => async (dispatch, getState) => {
  const APIClient = initApiClient(dispatch);
  dispatch({ type: PRODUCTION_FETCH_NEXT_BOX_FROM_LANE_START });

  if (getBoxPubkeyFromProductionDetailPath(getState())) {
    try {
      const nextBoxResponse = await APIClient.get(
        `/v1/backoffice/production_lanes/${productionLanePubKey}/next_box`,
        {
          status: getNextBoxStatus(getState()),
          current_pubkey: getBoxPubkeyFromProductionDetailPath(getState()),
        }
      );
      const nextBox = await nextBoxResponse.json();
      dispatch({ type: PRODUCTION_FETCH_NEXT_BOX_FROM_LANE_SUCCESS, nextBoxInLane: nextBox });
    } catch (err) {
      dispatch({ type: PRODUCTION_FETCH_NEXT_BOX_FROM_LANE_ERROR });
      // the typical error here will be a 404 if production lane is empty
      // we swallow the error to allow the process to continue
    }
  }
  return null;
};

export const updateProdItemsToNextStatus = () => async (dispatch, getState) => {
  const APIClient = initApiClient(dispatch);
  dispatch({ type: PRODUCTION_PROD_ITEMS_UPDATE_NEXT_STATUS_START });
  const pendingAndOrProducedProdItems = getPendingAndOrProducedProdItems(getState());

  if (isEmpty(pendingAndOrProducedProdItems)) {
    return;
  }

  try {
    if (getActionFromUrl(getState()) === 'processing') {
      await prodItemsBulkUpdate(
        APIClient,
        pendingAndOrProducedProdItems,
        'producing',
        getNextBoxInLane(getState())?.pubkey
      );
    }
    if (getActionFromUrl(getState()) === 'packing' && getNextBoxInLane(getState())?.need_buffer) {
      await prodItemsBulkUpdate(
        APIClient,
        pendingAndOrProducedProdItems,
        'buffering',
        getNextBoxInLane(getState())?.pubkey
      );
    }
    if (getActionFromUrl(getState()) === 'packing' && !getNextBoxInLane(getState())?.need_buffer) {
      await prodItemsBulkUpdate(
        APIClient,
        pendingAndOrProducedProdItems,
        'packing',
        getNextBoxInLane(getState())?.pubkey
      );
    }
    dispatch({ type: PRODUCTION_PROD_ITEMS_UPDATE_NEXT_STATUS_SUCCESS });
  } catch (error) {
    dispatch({ type: PRODUCTION_PROD_ITEMS_UPDATE_NEXT_STATUS_ERROR, error: error.message });
    logSentryError('[Production Actions] updateProdItemsToNextStatus', error);
    throw error;
  }
};
