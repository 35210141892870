export default [
  { value: null, label: 'Select the reason...' },
  { value: 'creative_order', label: 'Creative' },
  { value: 'urban_cowboy_order', label: 'Urban Cowboy order' },
  { value: 'reformulation', label: 'A reformulation' },
  { value: 'lost_package', label: 'A lost package' },
  { value: 'damaged_order', label: 'A damaged order' },
  { value: 'order_error', label: 'Order error' },
  { value: 'missing_item', label: 'Missing item' },
  { value: 'employee_order', label: 'Employee order' },
  { value: 'influencer_order', label: 'Influencer order' },
  { value: 'other', label: 'Other' },
];
