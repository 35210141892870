import assign from 'lodash/fp/assign';
import concat from 'lodash/fp/concat';
import filter from 'lodash/fp/filter';
import findIndex from 'lodash/fp/findIndex';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import _ from 'lodash/fp/placeholder';
import set from 'lodash/fp/set';

import createReducer from 'utils/reduxUtils';

import {
  CUSTOMER_ADDRESS_CREATE_ERROR,
  CUSTOMER_ADDRESS_CREATE_START,
  CUSTOMER_ADDRESS_CREATE_SUCCESS,
  CUSTOMER_ADDRESS_DELETE_ERROR,
  CUSTOMER_ADDRESS_DELETE_START,
  CUSTOMER_ADDRESS_DELETE_SUCCESS,
  CUSTOMER_ADDRESS_EDIT_ERROR,
  CUSTOMER_ADDRESS_EDIT_START,
  CUSTOMER_ADDRESS_EDIT_SUCCESS,
  CUSTOMER_CART_FETCH_ERROR,
  CUSTOMER_CART_FETCH_START,
  CUSTOMER_CART_FETCH_SUCCESS,
  CUSTOMER_COUPONS_FETCH_ERROR,
  CUSTOMER_COUPONS_FETCH_START,
  CUSTOMER_COUPONS_FETCH_SUCCESS,
  CUSTOMER_CREATE_START,
  CUSTOMER_CREATE_SUCCESS,
  CUSTOMER_DELETE_ERROR,
  CUSTOMER_DELETE_START,
  CUSTOMER_DELETE_SUCCESS,
  CUSTOMER_ERROR_DETECTION_ERROR,
  CUSTOMER_ERROR_DETECTION_START,
  CUSTOMER_ERROR_DETECTION_SUCCESS,
  CUSTOMER_FETCH_ERROR,
  CUSTOMER_FETCH_START,
  CUSTOMER_FETCH_SUCCESS,
  CUSTOMER_FORMULAS_FETCH_ERROR,
  CUSTOMER_FORMULAS_FETCH_START,
  CUSTOMER_FORMULAS_FETCH_SUCCESS,
  CUSTOMER_ORDERS_FETCH_ERROR,
  CUSTOMER_ORDERS_FETCH_START,
  CUSTOMER_ORDERS_FETCH_SUCCESS,
  CUSTOMER_PROFILES_FETCH_ERROR,
  CUSTOMER_PROFILES_FETCH_START,
  CUSTOMER_PROFILES_FETCH_SUCCESS,
  CUSTOMER_SELFIES_FETCH_ERROR,
  CUSTOMER_SELFIES_FETCH_START,
  CUSTOMER_SELFIES_FETCH_SUCCESS,
  CUSTOMER_STYLISTS_FETCH_ERROR,
  CUSTOMER_STYLISTS_FETCH_START,
  CUSTOMER_STYLISTS_FETCH_SUCCESS,
  CUSTOMER_SUBSCRIPTIONS_FETCH_ERROR,
  CUSTOMER_SUBSCRIPTIONS_FETCH_START,
  CUSTOMER_SUBSCRIPTIONS_FETCH_SUCCESS,
  CUSTOMER_SURVEYS_FETCH_ERROR,
  CUSTOMER_SURVEYS_FETCH_START,
  CUSTOMER_SURVEYS_FETCH_SUCCESS,
  CUSTOMER_UPDATE_START,
  CUSTOMER_UPDATE_SUCCESS,
  CUSTOMERS_FETCH_ERROR,
  CUSTOMERS_FETCH_START,
  CUSTOMERS_FETCH_SUCCESS,
} from './actionTypes';
import initialState from './initialState';

const reducerToActionTypeMap = {
  [CUSTOMERS_FETCH_START]: assign(_, { loading: true }),
  [CUSTOMERS_FETCH_SUCCESS]: (state, action) =>
    assign(state, {
      customers: action.data.customers,
      pages: action.data.pages,
      loading: false,
    }),
  [CUSTOMERS_FETCH_ERROR]: assign(_, { loading: false }),

  [CUSTOMER_ORDERS_FETCH_START]: assign(_, { loading: true }),
  [CUSTOMER_ORDERS_FETCH_SUCCESS]: (state, action) =>
    assign(state, {
      orders: action.data.orders,
      pages: action.data.pages,
      loading: false,
    }),
  [CUSTOMER_ORDERS_FETCH_ERROR]: assign(_, { loading: false }),

  [CUSTOMER_FETCH_START]: assign(_, { loading: true }),
  [CUSTOMER_FETCH_SUCCESS]: (state, action) =>
    assign(state, { customers: [action.customer], loading: false }),
  [CUSTOMER_FETCH_ERROR]: assign(_, { error: true, loading: false }),

  [CUSTOMER_CART_FETCH_START]: assign(_, { loading: true }),
  [CUSTOMER_CART_FETCH_SUCCESS]: (state, action) =>
    assign(state, { cart: action.cart, loading: false }),
  [CUSTOMER_CART_FETCH_ERROR]: assign(_, { loading: false }),

  [CUSTOMER_FORMULAS_FETCH_START]: assign(_, { loading: true }),
  [CUSTOMER_FORMULAS_FETCH_SUCCESS]: (state, action) =>
    assign(state, {
      formulasPreviewSkincare: action.data?.formulasPreviewSkincare,
      formulasPreviewHaircare: action.data?.formulasPreviewHaircare,
      hairProfileUpdatedAt: action.data.hairProfileUpdatedAt,
      loading: false,
    }),
  [CUSTOMER_FORMULAS_FETCH_ERROR]: assign(_, { loading: false }),

  [CUSTOMER_CREATE_START]: assign(_, { loading: true, error: false }),
  [CUSTOMER_CREATE_SUCCESS]: (state, action) =>
    assign(state, { customers: [action.customer], loading: false }),

  [CUSTOMER_UPDATE_START]: assign(_, { loading: true, error: false }),
  [CUSTOMER_UPDATE_SUCCESS]: (state, action) =>
    assign(state, { customers: [action.customer], loading: false }),

  [CUSTOMER_DELETE_START]: assign(_, { loading: true, error: false }),
  [CUSTOMER_DELETE_SUCCESS]: (state, action) =>
    assign(state, { customers: [action.customer], loading: false }),
  [CUSTOMER_DELETE_ERROR]: (state, action) =>
    assign(state, { error: action.error, loading: false }),
  [CUSTOMER_SUBSCRIPTIONS_FETCH_START]: assign(_, { loading: true }),
  [CUSTOMER_SUBSCRIPTIONS_FETCH_SUCCESS]: (state, action) =>
    assign(state, {
      subscriptions: action.subscriptions,
      loading: false,
    }),
  [CUSTOMER_SUBSCRIPTIONS_FETCH_ERROR]: assign(_, { loading: false }),

  [CUSTOMER_PROFILES_FETCH_START]: assign(_, { loading: true }),
  [CUSTOMER_PROFILES_FETCH_SUCCESS]: (state, action) =>
    assign(state, {
      profiles: action.profiles,
      loading: false,
    }),
  [CUSTOMER_PROFILES_FETCH_ERROR]: assign(_, { loading: false }),

  [CUSTOMER_SELFIES_FETCH_START]: assign(_, { loading: true, error: false }),
  [CUSTOMER_SELFIES_FETCH_SUCCESS]: (state, action) =>
    assign(state, { imageDocs: { [action.pubkey]: action.imageDocs, loading: false } }),
  [CUSTOMER_SELFIES_FETCH_ERROR]: (state, action) =>
    assign(state, { error: action.error, loading: false }),

  [CUSTOMER_ERROR_DETECTION_START]: assign(_, { error: false }),
  [CUSTOMER_ERROR_DETECTION_SUCCESS]: (state, action) =>
    assign(state, { errorDetectionResults: { [action.pubkey]: action.errorDetectionResults } }),
  [CUSTOMER_ERROR_DETECTION_ERROR]: (state, action) => assign(state, { error: action.error }),

  [CUSTOMER_ADDRESS_CREATE_START]: assign(_, { loading: true, error: false }),
  [CUSTOMER_ADDRESS_CREATE_SUCCESS]: (state, action) => {
    const currentAddresses = get(`customers.0.addresses`, state);
    const updatedAddresses = concat(action.createdAddress, currentAddresses);
    const newState = set(`customers.0.addresses`, updatedAddresses)(state);
    return assign(newState, { loading: false, error: false });
  },
  [CUSTOMER_ADDRESS_CREATE_ERROR]: (state, action) =>
    assign(state, { error: action.error, loading: false }),

  [CUSTOMER_ADDRESS_EDIT_START]: assign(_, { loading: true, error: false }),
  [CUSTOMER_ADDRESS_EDIT_SUCCESS]: (state, action) => {
    const currentAddresses = get(`customers.0.addresses`, state);
    const index = findIndex({ pubkey: action.editedAddress.pubkey }, currentAddresses);
    const newState = set(`customers.0.addresses.${index}`, action.editedAddress)(state);
    return assign(newState, { loading: false, error: false });
  },
  [CUSTOMER_ADDRESS_EDIT_ERROR]: (state, action) =>
    assign(state, { error: action.error, loading: false }),

  [CUSTOMER_ADDRESS_DELETE_START]: assign(_, { loading: true, error: false }),
  [CUSTOMER_ADDRESS_DELETE_SUCCESS]: (state, action) => {
    const updatedAddresses = flow(
      get(`customers.0.addresses`),
      filter(item => item.pubkey !== action.addressPubkey)
    )(state);
    const newState = set(`customers.0.addresses`, updatedAddresses)(state);
    return assign(newState, { loading: false, error: false });
  },
  [CUSTOMER_ADDRESS_DELETE_ERROR]: (state, action) =>
    assign(state, { error: action.error, loading: false }),

  [CUSTOMER_COUPONS_FETCH_START]: assign(_, { loading: true }),
  [CUSTOMER_COUPONS_FETCH_SUCCESS]: (state, action) => {
    const { credits, promos, creditsPages, promosPages } = action.data;
    const {
      credits: oldCredits,
      promos: oldPromos,
      creditsPages: oldCreditsPages,
      promosPages: oldPromosPages,
    } = state.coupons;

    const newState = set(
      'coupons',
      {
        credits: credits ?? oldCredits,
        promos: promos ?? oldPromos,
        creditsPages: creditsPages ?? oldCreditsPages,
        promosPages: promosPages ?? oldPromosPages,
      },
      state
    );

    return assign(newState, { loading: false });
  },
  [CUSTOMER_COUPONS_FETCH_ERROR]: assign(_, { loading: false }),

  [CUSTOMER_SURVEYS_FETCH_START]: assign(_, { loading: true }),
  [CUSTOMER_SURVEYS_FETCH_SUCCESS]: (state, action) =>
    assign(state, {
      surveys: action.data.surveys,
      pages: action.data.pages,
      loading: false,
    }),
  [CUSTOMER_SURVEYS_FETCH_ERROR]: assign(_, { loading: false }),

  [CUSTOMER_STYLISTS_FETCH_START]: assign(_, { loading: true }),
  [CUSTOMER_STYLISTS_FETCH_SUCCESS]: (state, action) =>
    assign(state, {
      stylists: action.data.stylists,
      loading: false,
    }),
  [CUSTOMER_STYLISTS_FETCH_ERROR]: assign(_, { loading: false }),
};
export default createReducer(reducerToActionTypeMap, initialState);
