import get from 'lodash/fp/get';

import jwtDecode from 'jwt-decode';
import { createSelector } from 'reselect';
import type { RootState } from 'store';
import type { OmsSelector } from 'types/common';

type AuthState = RootState['auth'];

// auth
const getAuth: OmsSelector<AuthState> = state => state.auth;

export const status: OmsSelector<AuthState['status']> = createSelector(getAuth, get('status'));
export const getError: OmsSelector<AuthState['error']> = createSelector(getAuth, get('error'));

export const getAccessToken: OmsSelector<AuthState['accessToken']> = createSelector(
  getAuth,
  get('accessToken')
);

const getDecodedAccessToken: OmsSelector<string> = createSelector(getAccessToken, accessToken => {
  try {
    return jwtDecode(accessToken);
  } catch {
    return null;
  }
});

export const getUserId: OmsSelector<string> = createSelector(getDecodedAccessToken, get('user_id'));
