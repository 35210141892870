import React, { Suspense } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';

import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import assign from 'lodash/fp/assign';

import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import type { ThemeOptions } from '@mui/material/styles/createTheme';
import styled from '@mui/material/styles/styled';
import { StyledEngineProvider } from '@mui/system';
import { LicenseInfo as MuiLicenseInfo } from '@mui/x-license-pro';
import { GoogleOAuthProvider } from '@react-oauth/google';

import AppMenu from './Components/AppMenu';
import Authentication from './Components/Authentication';
import CenteredProgress from './Components/CenteredProgress';
import ErrorFallback, { logError } from './Components/ErrorFallback';
import TempRouteComponent from './Components/TempRouteComponent';
import { fetchAuth } from './dux/auth/thunks';
import SigninScene from './Scenes/SignIn';
import SigninWithE2E from './Scenes/SignInE2E';
import SignoutScene from './Scenes/SignOut';
import getEnv from './utils/getEnv';
import { customizedMui, proseTheme } from './RootTheme';
import store, { history } from './store';

MuiLicenseInfo.setLicenseKey(getEnv('REACT_APP_MUI_PRO'));

// createMuiTheme adds default values to the theme
const muiTheme = createTheme(customizedMui as ThemeOptions);

const theme = assign(muiTheme, { prose: proseTheme });

const GridLayout = styled('div')`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr;
  height: 100%;
  max-width: 100%;
  overflow: auto;
`;

store.dispatch(fetchAuth());

const Root = () => (
  <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
    <GoogleOAuthProvider clientId={getEnv('REACT_APP_GOOGLE_AUTH_ID')}>
      <Provider store={store}>
        <Router history={history}>
          <CssBaseline />
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={theme}>
              <EmotionThemeProvider theme={theme}>
                <Routes>
                  <Route path="/auth">
                    <Route path="signin" element={<SigninScene />} />
                    <Route path="signout" element={<SignoutScene />} />
                    <Route path="signin/e2e" element={<SigninWithE2E />} />
                  </Route>
                  <Route
                    element={
                      <Authentication>
                        <GridLayout>
                          <AppMenu />
                          <Suspense fallback={<CenteredProgress />}>
                            <TempRouteComponent />
                          </Suspense>
                        </GridLayout>
                      </Authentication>
                    }
                    path="*"
                  />
                </Routes>
              </EmotionThemeProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </Router>
      </Provider>
    </GoogleOAuthProvider>
  </ErrorBoundary>
);

export default Root;
