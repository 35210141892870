export const ORDERS_FETCH_ORDERS_START = 'ORDERS_FETCH_ORDERS_START';
export const ORDERS_FETCH_ORDERS_SUCCESS = 'ORDERS_FETCH_ORDERS_SUCCESS';
export const ORDERS_FETCH_ORDERS_SUCCESS_DATA = 'ORDERS_FETCH_ORDERS_SUCCESS_DATA';
export const ORDERS_FETCH_ORDERS_ERROR = 'ORDERS_FETCH_ORDERS_ERROR';
export const ORDERS_LOAD_START = 'ORDERS_LOAD_START';
export const ORDERS_LOAD_END = 'ORDERS_LOAD_END';

export const ORDER_FETCH_START = 'ORDER_FETCH_START';
export const ORDER_FETCH_SUCCESS = 'ORDER_FETCH_SUCCESS';

export const ORDERS_DISPATCHABLE_FETCH_START = 'ORDERS_DISPATCHABLE_FETCH_START';
export const ORDERS_DISPATCHABLE_FETCH_SUCCESS = 'ORDERS_DISPATCHABLE_FETCH_SUCCESS';
export const ORDERS_DISPATCHABLE_FETCH_ERROR = 'ORDERS_DISPATCHABLE_FETCH_ERROR';

export const ORDER_REMOVE_ON_HOLD_START = 'ORDER_REMOVE_ON_HOLD_START';
export const ORDER_REMOVE_ON_HOLD_SUCCESS = 'ORDER_REMOVE_ON_HOLD_SUCCESS';

export const FORMULA_FETCH_START = 'FORMULA_FETCH_START';
export const FORMULA_FETCH_SUCCESS = 'FORMULA_FETCH_SUCCESS';
export const FORMULA_FETCH_ERROR = 'FORMULA_FETCH_ERROR';

export const ORDER_ADDRESS_EDIT_START = 'ORDER_ADDRESS_EDIT_START';
export const ORDER_ADDRESS_EDIT_SUCCESS = 'ORDER_ADDRESS_EDIT_SUCCESS';
export const ORDER_ADDRESS_EDIT_ERROR = 'ORDER_ADDRESS_EDIT_ERROR';
