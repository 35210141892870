import { configureStore } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';
import { createLogger } from 'redux-logger';

import { createBrowserHistory } from 'history';

import dataportalApi from 'dux/dataportal/apiSlice';

import fetchFeatureFlagsOnAuthSuccess from './dux/featureFlags/middlewares';
import createSentryReduxEnhancer from './utils/createSentryReduxEnhancer';
import getEnv from './utils/getEnv';
import createRootReducer from './reducers';

const DEBUG = getEnv('NODE_ENV') === 'development';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const rootReducer = createRootReducer(routerReducer);

const sentryReduxEnhancer = createSentryReduxEnhancer();

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .prepend(routerMiddleware)
      .concat(fetchFeatureFlagsOnAuthSuccess)
      .concat(DEBUG ? [createLogger({ collapsed: true })] : [])
      .concat(dataportalApi.middleware),
  devTools: true,
  enhancers: [sentryReduxEnhancer],
});

export default store;

export const history = createReduxHistory(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
