import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';

import { humanize } from 'helpers';
import { createSelector } from 'reselect';

import { variantIsSupplementJar } from 'utils/variants';

import { shouldShowMicroholdPalletizationPhase5 } from 'dux/featureFlags/selectors';

import { getBufferScannerSetting, getCategory, getViewMode } from '../../settings/selectors';
import { getBoxContainsQualityTestBatch } from '../microhold/selectors';
import {
  getEndOfLaneComplexItemScanned,
  getPrepackItems,
  isShippingLabelMandatory,
} from '../scan/selectors';
import {
  getBoxDetail,
  getBoxHasCarrierDiscrepancy,
  getNeedBuffer,
  getOrderItemsFromBox,
  getProdItems,
  getProductionDetails,
  getScannedPubkeys,
  getScannedShippingLabel,
  getSelectedEndOfLaneScreen,
  getSettingsState,
  getShippingCarrier,
  getShippingCountry,
} from '../selectors';

export const getBoxStatus = createSelector(getBoxDetail, get('status'));
export const getBoxBufferBin = createSelector(getBoxDetail, get('buffer_bin'));

export const getComplementaryItemsInBox = createSelector(getBoxDetail, get('complementaryItems'));
export const getVariantsInBox = createSelector(getProductionDetails, get('variantsScannedPubkey'));

export const getAllProdItemsDoneOnAutomationEolaAndEolb = createSelector(
  getProdItems,
  getOrderItemsFromBox,
  getScannedPubkeys,
  getSelectedEndOfLaneScreen,
  getCategory,
  (prodItems, orderItems, scannedPubkeys, selectedEndOfLaneScreen, category) => {
    const firstPubkeyScanned = scannedPubkeys[0];
    const laneOfFirstPubkeyScanned = flow(
      find({ pubkey: firstPubkeyScanned }),
      get('production_lane')
    )(prodItems);

    const productionItemProducedOnLane = filter(
      productionItem =>
        productionItem.status === 'produced' &&
        productionItem.production_lane === laneOfFirstPubkeyScanned
    )(prodItems);

    const productionItemProducibleOnLane = filter(
      productionItem =>
        includes(productionItem.status, ['producing', 'produced']) &&
        productionItem.production_lane === laneOfFirstPubkeyScanned
    )(prodItems);

    if (category === 'skincare' && selectedEndOfLaneScreen === 'eola') {
      return productionItemProducedOnLane.length > 0;
    }
    return (
      productionItemProducedOnLane.length > 0 &&
      productionItemProducedOnLane.length === productionItemProducibleOnLane.length
    );
  }
);

export const getAllProdItemsPendingComplex = createSelector(
  getProdItems,
  getEndOfLaneComplexItemScanned,
  getOrderItemsFromBox,
  getScannedPubkeys,
  (prodItems, endOfLaneComplexItemScanned, orderItems, scannedPubkeys) => {
    const firstPubkeyScanned = scannedPubkeys[0];
    const laneOfFirstPubkeyScanned = flow(
      find({ pubkey: firstPubkeyScanned }),
      get('production_lane')
    )(prodItems);

    const productionItemProducedOnLane = filter(
      productionItem =>
        productionItem.status === 'producing_step_2' &&
        productionItem.production_lane === laneOfFirstPubkeyScanned
    )(prodItems);

    return (
      productionItemProducedOnLane.length > 0 &&
      productionItemProducedOnLane.length === endOfLaneComplexItemScanned?.production_items.length
    );
  }
);

export const getScannableItemsQuantityInBoxForBufferTrolley = createSelector(
  getOrderItemsFromBox,
  getScannedPubkeys,
  getProdItems,
  (orderItems, scannedPubkeys, prodItems) => {
    const firstPubkeyScanned = scannedPubkeys[0];
    const laneOfFirstPubkeyScanned = flow(
      find({ pubkey: firstPubkeyScanned }),
      get('production_lane')
    )(prodItems);

    const prodItemBufferingOnLane = filter(
      prodItem =>
        includes(prodItem.status, ['buffering']) &&
        prodItem.production_lane === laneOfFirstPubkeyScanned
    )(prodItems);

    return prodItemBufferingOnLane?.length;
  }
);

export const allProdItemsProducedOnLane = createSelector(
  getProdItems,
  getOrderItemsFromBox,
  getSettingsState,
  (prodItems, orderItems, { selectedLane }) => {
    const prodItemProducedOnLane = filter(
      prodItem =>
        includes(prodItem.status, ['produced', 'buffering']) &&
        prodItem.production_lane === selectedLane
    )(prodItems);

    const notProducedAndProducedProdItems = filter(
      prodItem =>
        includes(prodItem.status, ['producing', 'pending', 'produced']) &&
        prodItem.production_lane === selectedLane
    )(prodItems);

    return (
      prodItemProducedOnLane.length > 0 &&
      prodItemProducedOnLane.length === notProducedAndProducedProdItems.length
    );
  }
);

export const getScannableItemsInBoxQuantityAtPacking = createSelector(
  getOrderItemsFromBox,
  orderItems =>
    orderItems?.reduce((acc, v) => {
      if (!v.variant.is_scannable) {
        return acc;
      }

      return acc + v.quantity_in_box;
    }, 0)
);

export const getScannableItemsInBoxQuantityFormulaAndAccessory = createSelector(
  getPrepackItems,
  prepackItems =>
    prepackItems?.reduce((acc, v) => {
      if (!v.variant.is_scannable || (!v.variant.is_formula && !v.variant.is_bulky)) {
        return acc;
      }
      if (variantIsSupplementJar(v.variant)) {
        // Supplements need the front and back codes to be scanned
        return acc + v.quantity_in_box * 2;
      }
      return acc + v.quantity_in_box;
    }, 0)
);

export const getScannedItemsInBoxQuantity = createSelector(
  getScannedPubkeys,
  getVariantsInBox,
  getComplementaryItemsInBox,
  (scannedPubkeys, variantsScanned, complementaryItems) => {
    let count = 0;
    count += scannedPubkeys.length;
    count += complementaryItems?.reduce((acc, v) => acc + v.scannedQuantity, 0) || 0;
    count += variantsScanned?.reduce((acc, v) => acc + v.scannedQuantity, 0) || 0;
    return count;
  }
);

export const getShippingWording = createSelector(
  [getShippingCarrier, getBoxHasCarrierDiscrepancy, getShippingCountry],
  (shippingCarrier, hasCarrierDiscrepancy, shippingCountry) => {
    if (hasCarrierDiscrepancy) {
      return `${humanize(shippingCarrier)} label - Bring to Supervisor!`;
    }
    if (shippingCountry === 'CA') {
      return 'Canadian Order, Place on Canadian Palette';
    }
    return 'Ship!';
  }
);

export const getButtonActionText = createSelector(
  getCategory,
  getViewMode,
  getBoxBufferBin,
  getBoxContainsQualityTestBatch,
  getBoxStatus,
  getNeedBuffer,
  getScannableItemsInBoxQuantityAtPacking,
  getScannedItemsInBoxQuantity,
  getBufferScannerSetting,
  getSelectedEndOfLaneScreen,
  getShippingWording,
  getScannedShippingLabel,
  isShippingLabelMandatory,
  shouldShowMicroholdPalletizationPhase5,
  (
    category,
    viewMode,
    bufferBin,
    boxContainsQualityTestBatch,
    boxStatus,
    needBuffer,
    scannableItemsQuantityAtPacking,
    scannedItemsQuantity,
    bufferScannerMode,
    selectedEndOfLaneScreen,
    shippingWording,
    isScanShippingLabel,
    isMandatoryShippingLabel,
    shouldShowMicrohold
  ) => {
    switch (viewMode) {
      case 'prepack':
        if (needBuffer && !includes(boxStatus, ['buffered', 'prepacking', 'prepacked'])) {
          return `Multi-lane order, Add to buffer trolley`;
        }
        return 'Add box to packing trolley';
      case 'automationScreens':
        if (includes(selectedEndOfLaneScreen, ['eolb']) || Boolean(needBuffer)) {
          return 'multi-lane order, add to buffer trolley';
        }

        if (category === 'skincare') return 'Add to Buffer Trolley';
        return 'Add to pre-pack trolley';
      case 'production-lab-automation':
        if (category === 'skincare') {
          return 'Add to packing trolley';
        }
        return !needBuffer ? 'Add to pre-pack trolley' : 'Multi-lane order, Add to buffer trolley';
      case 'endoflanebufferspace':
        if (!needBuffer) return 'Add to pre-pack trolley';
        if (bufferBin) {
          return `Buffer to ${includes('AA-', bufferBin?.coordinates) ? 'DEFECT' : ''} ${
            bufferBin?.coordinates
          }`;
        }
        return 'No buffer bin associated';
      case 'packingstation':
        if (boxContainsQualityTestBatch && shouldShowMicrohold) {
          return 'To Quality Hold';
        }
        if (bufferScannerMode !== 'partial' && bufferScannerMode !== 'full') {
          return shippingWording;
        }
        if (scannedItemsQuantity < scannableItemsQuantityAtPacking) {
          return `${shippingWording} (${scannedItemsQuantity}/${scannableItemsQuantityAtPacking} items scanned)`;
        }
        if (
          isMandatoryShippingLabel &&
          scannedItemsQuantity === scannableItemsQuantityAtPacking &&
          !isScanShippingLabel &&
          bufferScannerMode !== 'partial'
        ) {
          return `Scan shipping label to ship box`;
        }
        return shippingWording;
      default:
        return 'oops';
    }
  }
);

export const isButtonActionDisabled = createSelector(
  getViewMode,
  getScannableItemsInBoxQuantityAtPacking,
  getScannedItemsInBoxQuantity,
  getBufferScannerSetting,
  getSelectedEndOfLaneScreen,
  getScannedShippingLabel,
  allProdItemsProducedOnLane,
  getScannableItemsQuantityInBoxForBufferTrolley,
  getAllProdItemsDoneOnAutomationEolaAndEolb,
  getAllProdItemsPendingComplex,
  getOrderItemsFromBox,
  getScannableItemsInBoxQuantityFormulaAndAccessory,
  getCategory,
  isShippingLabelMandatory,
  (
    viewMode,
    scannableItemsQuantityAtPacking,
    scannedItemsQuantity,
    bufferScannerMode,
    selectedEndOfLaneScreen,
    scannedShippingLabel,
    allProdItemsAreProducedOnLane,
    scannableItemsQuantityInBoxForBufferTrolley,
    allProdItemsDoneOnAutomation,
    allProdItemsPendingComplex,
    orderItems,
    scannableFormulaAndAccessoryQuantity,
    category,
    isMandatoryShippingLabel
  ) => {
    if (viewMode === 'production-lab-automation') {
      return !allProdItemsAreProducedOnLane;
    }
    if (viewMode === 'automationScreens') {
      if (selectedEndOfLaneScreen === 'eolbComplex') {
        return !allProdItemsPendingComplex;
      }
      return !allProdItemsDoneOnAutomation;
    }
    if (viewMode === 'packingstation') {
      if (bufferScannerMode === 'full') {
        return (
          scannedItemsQuantity < scannableItemsQuantityAtPacking ||
          (isMandatoryShippingLabel && !scannedShippingLabel)
        );
      }
      if (bufferScannerMode === 'partialAndShippingLabelMandatory') {
        return isMandatoryShippingLabel && !scannedShippingLabel;
      }
      return (
        bufferScannerMode === 'partial' && scannedItemsQuantity < scannableItemsQuantityAtPacking
      );
    }
    if (category === 'haircare' && viewMode === 'endoflanebufferspace') {
      return !(scannedItemsQuantity === scannableItemsQuantityInBoxForBufferTrolley);
    }
    if (viewMode === 'prepack') {
      if (bufferScannerMode === 'full') {
        return scannedItemsQuantity !== scannableFormulaAndAccessoryQuantity;
      }
      if (bufferScannerMode === 'partial') {
        return scannedItemsQuantity !== scannableFormulaAndAccessoryQuantity;
      }
      return false;
    }

    return false;
  }
);
