import assign from 'lodash/fp/assign';
import set from 'lodash/fp/set';

import createReducer from 'utils/reduxUtils';

import {
  OBJECT_DISPATCH_DISPATCHING_DONE,
  OBJECT_DISPATCH_DISPATCHING_ERROR,
  OBJECT_DISPATCH_DISPATCHING_START,
  OBJECT_DISPATCH_PRODUCTION_LANE_DESELECTED,
  OBJECT_DISPATCH_PRODUCTION_LANE_SELECTED,
  OBJECT_DISPATCH_PRODUCTION_LANE_SELECTION_RESET,
} from './actionTypes';
import initialState from './initialState';

const reducerToActionTypeMap = {
  [OBJECT_DISPATCH_PRODUCTION_LANE_SELECTION_RESET]: state => set('selectedLanes', {})(state),
  [OBJECT_DISPATCH_PRODUCTION_LANE_SELECTED]: (state, { pubkey }) =>
    set(['selectedLanes', pubkey], true)(state),
  [OBJECT_DISPATCH_PRODUCTION_LANE_DESELECTED]: (state, { pubkey }) =>
    set(['selectedLanes', pubkey], false)(state),
  [OBJECT_DISPATCH_DISPATCHING_START]: state => set('isDispatching', true)(state),
  [OBJECT_DISPATCH_DISPATCHING_DONE]: state => set('isDispatching', false)(state),
  [OBJECT_DISPATCH_DISPATCHING_ERROR]: (state, { error }) =>
    assign(state, { error, isDispatching: false }),
};
export default createReducer(reducerToActionTypeMap, initialState);
