import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';
import map from 'lodash/fp/map';

import { humanize } from 'helpers';
import { createSelector } from 'reselect';

import { getActionFromUrl, getLanePubkeyByLaneName } from '../../router/selectors';
import { getLanes, getProductionLane, getProductionState } from '../selectors';

export const getToPackBoxesCount = createSelector([getProductionLane, getLanes], (boxLane, lanes) =>
  flow(find({ pubkey: boxLane }), get('stats'), find({ status: 'produced' }), get('count'))(lanes)
);

export const getToProduceBoxesCount = createSelector(
  [getProductionLane, getLanes],
  (boxLane, lanes) =>
    flow(find({ pubkey: boxLane }), get('stats'), find({ status: 'pending' }), get('count'))(lanes)
);

export const getNextBoxInLane = createSelector(getProductionState, state => state?.nextBoxInLane);
export const getNextPreview = createSelector(getProductionState, state => state?.nextPreview);

export const getPendingAndOrProducedProdItems = createSelector(
  getNextBoxInLane,
  getLanePubkeyByLaneName,
  (nextBox, lanePubkey) =>
    filter(
      prodItem =>
        prodItem?.production_lane === lanePubkey &&
        includes(prodItem?.status, ['pending', 'produced']),
      nextBox?.proditems
    )
);

export const getNextBoxStatus = createSelector(
  getActionFromUrl,
  getToProduceBoxesCount,
  getToPackBoxesCount,
  (currentBoxStatus, toProduceBoxesCount, toPackBoxesCount) => {
    switch (currentBoxStatus) {
      case 'processing': // When in preview the box is not processed yet
      case 'processed':
        return toProduceBoxesCount === 0 ? 'processing' : 'pending';
      case 'packing':
        return toPackBoxesCount === 0 ? 'packing' : 'processed';
      default:
        return null;
    }
  }
);

export const getFormattedNextPreview = createSelector(getNextBoxInLane, nextBoxInLane => {
  if (!nextBoxInLane) return null;

  return {
    name: `${nextBoxInLane?.customer?.first_name} ${nextBoxInLane?.customer?.last_name}`,
    products: flow(
      filter({ variant: { box_slot: 1 } }),
      map(item => ({
        name: humanize(item?.item_object?.skeleton?.slug ?? item?.variant?.slug),
        quantity: item?.quantity,
      }))
    )(nextBoxInLane?.items),
  };
});
