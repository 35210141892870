import { combineReducers } from '@reduxjs/toolkit';

import authReducer from 'dux/auth/slice';
import BoxesReducer from 'dux/boxes/slice';
import bufferSpacesReducer from 'dux/bufferSpaces/slice';
import countriesReducer from 'dux/countries/slice';
import couponsReducer from 'dux/coupons/slice';
import customersReducer from 'dux/customer/reducers';
import dataportalApi from 'dux/dataportal/apiSlice';
import exportsReducer from 'dux/exports/slice';
import FastConsultationReducer from 'dux/fastconsultation/slice';
import featureFlagsReducer from 'dux/featureFlags/reducers';
import feedbacksReducer from 'dux/feedbacks/slice';
import giftsReducer from 'dux/gifts/slice';
import labInfosReducer from 'dux/lab-infos/slice';
import metadataReducer from 'dux/metadata/slice';
import objectDispatchReducer from 'dux/objectDispatch/reducers';
import ordersReducer from 'dux/orders/reducers';
import partialRefundReducer from 'dux/partialRefund/slice';
import priceExperimentsReducer from 'dux/priceExperiments/slice';
import productionReducer from 'dux/production/reducers';
import settingsReducer from 'dux/settings/slice';
import subscriptionsReducer from 'dux/subscriptions/slice';
import surveysReducer from 'dux/surveys/slice';
import TeammatesReducer from 'dux/teammates/reducers';
import userTagsReducer from 'dux/userTags/slice';
import variantsReducer from 'dux/variants/slice';

const createRootReducer = routerReducer =>
  combineReducers({
    router: routerReducer,
    auth: authReducer,
    boxes: BoxesReducer,
    bufferSpaces: bufferSpacesReducer,
    countries: countriesReducer,
    coupons: couponsReducer,
    customers: customersReducer,
    exports: exportsReducer,
    fastconsultation: FastConsultationReducer,
    featureFlags: featureFlagsReducer,
    feedbacks: feedbacksReducer,
    gifts: giftsReducer,
    metadata: metadataReducer,
    orders: ordersReducer,
    partialRefund: partialRefundReducer,
    priceExperiments: priceExperimentsReducer,
    production: productionReducer,
    settings: settingsReducer,
    [dataportalApi.reducerPath]: dataportalApi.reducer,
    labInfos: labInfosReducer,
    subscriptions: subscriptionsReducer,
    surveys: surveysReducer,
    teammates: TeammatesReducer,
    userTags: userTagsReducer,
    variants: variantsReducer,
    objectDispatch: objectDispatchReducer,
  });

export default createRootReducer;
