import add from 'lodash/fp/add';
import concat from 'lodash/fp/concat';
import every from 'lodash/fp/every';
import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import first from 'lodash/fp/first';
import flatMap from 'lodash/fp/flatMap';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';
import map from 'lodash/fp/map';
import overSome from 'lodash/fp/overSome';
import _ from 'lodash/fp/placeholder';
import reduce from 'lodash/fp/reduce';
import some from 'lodash/fp/some';

import { createSelector } from 'reselect';
import type { RootState } from 'store';
import type { Nullable, OmsSelector } from 'types/common';

import { complementaryItemsUtils } from 'utils/complementaryItems';
import isExtrasOrder from 'utils/extras';

import type {
  Box,
  Boxes,
  BufferBin,
  Item,
  ItemObject,
  ProductionItem,
  Shipping,
  ShippingParcel,
  Variant,
} from './types';

type BoxesSlice = RootState['boxes'];

const getBoxesState: OmsSelector<BoxesSlice> = state => state.boxes;

export const getBoxes: OmsSelector<BoxesSlice['boxes']> = createSelector(
  getBoxesState,
  get('boxes')
);
export const getStatus: OmsSelector<BoxesSlice['status']> = createSelector(
  getBoxesState,
  get('status')
);
export const getError: OmsSelector<BoxesSlice['error']> = createSelector(
  getBoxesState,
  get('error')
);

export const getBoxByPubkey: OmsSelector<Box> = createSelector(
  getBoxes,
  (_state, props) => props.boxPubkey,
  (boxList, pubkey) => find({ pubkey }, boxList)
);

export const getBoxSerialNo: OmsSelector<Box['serial_no']> = createSelector(
  getBoxByPubkey,
  get('serial_no')
);
export const getBoxStatus: OmsSelector<Box['status']> = createSelector(
  getBoxByPubkey,
  get('status')
);
export const getBoxStatuses: OmsSelector<Box['statuses']> = createSelector(
  getBoxByPubkey,
  get('statuses')
);
export const getBoxItems: OmsSelector<Box['items']> = createSelector(getBoxByPubkey, get('items'));
export const getProdItems: OmsSelector<Box['proditems']> = createSelector(
  getBoxByPubkey,
  get('proditems')
);
export const getShippedAt: OmsSelector<Box['shipped_at']> = createSelector(
  getBoxByPubkey,
  get('shipped_at')
);
export const getUpdatedAt: OmsSelector<Box['updated_at']> = createSelector(
  getBoxByPubkey,
  get('updated_at')
);
export const getIsGiftBrush: OmsSelector<Box['is_gift_brush']> = createSelector(
  getBoxByPubkey,
  get('is_gift_brush')
);

export const getIsScannable: OmsSelector<Variant['is_scannable']> = createSelector(
  getBoxItems,
  some({ variant: { is_scannable: true } })
);

export const getQrCodeItems: OmsSelector<Box['items']> = createSelector(
  [getBoxItems, getBoxByPubkey],
  (items, box) =>
    flow(
      filter({ variant: { is_scannable: true } }),
      concat(_, complementaryItemsUtils({ box, orderItems: items }))
    )(items)
);

export const getIsFormula: OmsSelector<Variant['is_formula']> = createSelector(
  getBoxItems,
  some({ variant: { is_formula: true } })
);
export const getIsBoxContainsBrush: OmsSelector<boolean> = createSelector(
  getBoxItems,
  some({ variant: { product: { type: 'brush' } } })
);

export const getNumberOfPumps: OmsSelector<number> = createSelector(
  getBoxItems,
  flow(
    filter(
      overSome([
        { variant: { product: { type: 'pump' } } },
        { variant: { product: { type: 'dropper' } } },
      ])
    ),
    map('quantity'),
    reduce(add, 0)
  )
);

export const getBoxBufferBin: OmsSelector<Box['buffer_bin']> = createSelector(
  getBoxByPubkey,
  get('buffer_bin')
);
export const getBufferBinCoordinates: OmsSelector<BufferBin['coordinates']> = createSelector(
  getBoxBufferBin,
  get('coordinates')
);

export const getBoxPallet: OmsSelector<Box['pallet']> = createSelector(
  getBoxByPubkey,
  get('pallet')
);

export const getShippingParcel: OmsSelector<Box['shipping_parcel']> = createSelector(
  getBoxByPubkey,
  get('shipping_parcel')
);
export const getShippingParcelName: OmsSelector<ShippingParcel['name']> = createSelector(
  getShippingParcel,
  get('name')
);

const getShipping: OmsSelector<Box['shipping']> = createSelector(getBoxByPubkey, get('shipping'));
export const getShippingTrackingId: OmsSelector<Shipping['tracking_id']> = createSelector(
  getShipping,
  get('tracking_id')
);
export const getShippingTrackingUrl: OmsSelector<Shipping['tracking_url']> = createSelector(
  getShipping,
  get('tracking_url')
);
export const getShippingCarrier: OmsSelector<Shipping['carrier']> = createSelector(
  getShipping,
  get('carrier')
);

export const getShippingTrackingNumber: OmsSelector<Nullable<string>> = createSelector(
  [getShippingTrackingId, getShippingCarrier],
  (shippingTrackingId, shippingCarrier) => {
    if (!shippingTrackingId) return null;
    return shippingCarrier === 'pb' ? shippingTrackingId : `420480330029${shippingTrackingId}`;
  }
);

export const getBoxesByOrderPubkey: OmsSelector<Boxes> = createSelector(
  getBoxes,
  (_state, props) => props.orderPubkey,
  (boxes, pubkey) => filter({ order: { pubkey } }, boxes)
);

export const orderIsRefundable: OmsSelector<boolean> = createSelector(
  getBoxesByOrderPubkey,
  flow(
    flatMap(item => item.proditems),
    every(item => !includes(item.status, ['created', 'paid', 'preparing']))
  )
);

const getBrushItemObject: OmsSelector<Item['item_object']> = createSelector(
  getBoxItems,
  (_state, props) => props.pubkey,
  (boxItems, pubkey) => flow(find({ pubkey }), get('item_object'))(boxItems)
);

export const getBrushItemObjectPubkey: OmsSelector<ItemObject['pubkey']> = createSelector(
  getBrushItemObject,
  get('pubkey')
);

export const getBrushMonogram: OmsSelector<ItemObject['customization']> = createSelector(
  getBrushItemObject,
  get('customization')
);

export const getBrushStatus: OmsSelector<ProductionItem['status']> = createSelector(
  getBoxItems,
  (_state, props) => props.pubkey,
  (boxItems, pubkey) =>
    flow(find({ pubkey }), get('production_items'), first, get('status'))(boxItems)
);

// this selector will change as more products are added to extras order / we get a specific API field
export const isExtrasBox: OmsSelector<Nullable<boolean>> = createSelector(getBoxByPubkey, box => {
  if (!box) return null;
  return Boolean(isExtrasOrder(box.items));
});

export const getDispatchableItems: OmsSelector<Item[]> = createSelector(
  [getBoxItems, isExtrasBox],
  (boxItems, isExtras) => {
    return flow(
      filter(
        overSome(
          isExtras
            ? [
                { variant: { product: { type: 'pump' } } },
                { variant: { product: { type: 'dropper' } } },
              ]
            : [
                { variant: { is_formula: true } },
                { variant: { is_producible: true } },
                { variant: { is_bulky: true } },
              ]
        )
      ),
      filter(item => some({ status: 'created' }, item.production_items))
    )(boxItems);
  }
);
