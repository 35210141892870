import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';

import createReducer from 'utils/reduxUtils';

import {
  TEAMMATES_FETCH_SIGNED_IN_TEAMMATE_ERROR,
  TEAMMATES_FETCH_SIGNED_IN_TEAMMATE_START,
  TEAMMATES_FETCH_SIGNED_IN_TEAMMATE_SUCCESS,
  TEAMMATES_FETCH_TEAMMATES_SUCCESS,
  TEAMMATES_FETCH_TEAMMATES_SUCCESS_DATA,
  TEAMMATES_LOAD_END,
  TEAMMATES_LOAD_START,
} from './actionTypes';
import initialState from './initialState';

const reducerToActionTypeMap = {
  [TEAMMATES_LOAD_START]: state => set('loading', true)(state),
  [TEAMMATES_FETCH_SIGNED_IN_TEAMMATE_START]: set('loading', true),
  [TEAMMATES_LOAD_END]: state => set('loading', false)(state),
  [TEAMMATES_FETCH_TEAMMATES_SUCCESS]: (state, action) => set('teammates', action.teammates)(state),
  [TEAMMATES_FETCH_SIGNED_IN_TEAMMATE_SUCCESS]: (state, { signedInTeammate }) =>
    flow(set('loading', false), set('signedInTeammate', signedInTeammate))(state),
  [TEAMMATES_FETCH_SIGNED_IN_TEAMMATE_ERROR]: flow(
    set('loading', false),
    set('signedInTeammate', null)
  ),
  [TEAMMATES_FETCH_TEAMMATES_SUCCESS_DATA]: (state, action) => set('pages', action.pages)(state),
};
export default createReducer(reducerToActionTypeMap, initialState);
