export const OBJECT_DISPATCH_PRODUCTION_LANE_SELECTION_RESET =
  'OBJECT_DISPATCH_PRODUCTION_LANE_SELECTION_RESET';

export const OBJECT_DISPATCH_PRODUCTION_LANE_SELECTED = 'OBJECT_DISPATCH_PRODUCTION_LANE_SELECTED';

export const OBJECT_DISPATCH_PRODUCTION_LANE_DESELECTED =
  'OBJECT_DISPATCH_PRODUCTION_LANE_DESELECTED';

export const OBJECT_DISPATCH_DISPATCHING_START = 'OBJECT_DISPATCH_DISPATCHING_START';
export const OBJECT_DISPATCH_DISPATCHING_DONE = 'OBJECT_DISPATCH_DISPATCHING_DONE';

export const OBJECT_DISPATCH_DISPATCHING_ERROR = 'OBJECT_DISPATCH_DISPATCHING_ERROR';
