import assign from 'lodash/fp/assign';
import findIndex from 'lodash/fp/findIndex';
import _ from 'lodash/fp/placeholder';
import set from 'lodash/fp/set';

import createReducer from 'utils/reduxUtils';

import {
  FORMULA_FETCH_ERROR,
  FORMULA_FETCH_START,
  FORMULA_FETCH_SUCCESS,
  ORDER_ADDRESS_EDIT_ERROR,
  ORDER_ADDRESS_EDIT_START,
  ORDER_ADDRESS_EDIT_SUCCESS,
  ORDER_FETCH_START,
  ORDER_FETCH_SUCCESS,
  ORDER_REMOVE_ON_HOLD_START,
  ORDER_REMOVE_ON_HOLD_SUCCESS,
  ORDERS_DISPATCHABLE_FETCH_ERROR,
  ORDERS_DISPATCHABLE_FETCH_START,
  ORDERS_DISPATCHABLE_FETCH_SUCCESS,
  ORDERS_FETCH_ORDERS_ERROR,
  ORDERS_FETCH_ORDERS_SUCCESS,
  ORDERS_FETCH_ORDERS_SUCCESS_DATA,
  ORDERS_LOAD_END,
  ORDERS_LOAD_START,
} from './actionTypes';
import initialState from './initialState';

const reducerToActionTypeMap = {
  [ORDERS_LOAD_START]: state => assign(state, { loading: true, error: null }),
  [ORDERS_LOAD_END]: state => set('loading', false)(state),

  [ORDER_ADDRESS_EDIT_START]: assign(_, { loading: true, error: false }),
  [ORDER_ADDRESS_EDIT_SUCCESS]: (state, action) => {
    const newState = set(`orders.0.shipping_address`, action.editedAddress)(state);
    return assign(newState, { loading: false, error: false });
  },
  [ORDER_ADDRESS_EDIT_ERROR]: (state, action) =>
    assign(state, { error: action.error, loading: false }),

  [ORDERS_FETCH_ORDERS_SUCCESS]: (state, action) => set('orders', action.orders)(state),
  [ORDERS_FETCH_ORDERS_SUCCESS_DATA]: (state, action) => set('pages', action.pages)(state),
  [ORDERS_FETCH_ORDERS_ERROR]: (state, action) => set('error', action.error)(state),

  [ORDER_FETCH_START]: assign(_, { loading: true }),
  [ORDER_FETCH_SUCCESS]: (state, action) =>
    assign(state, { orders: [action.order], loading: false }),

  [ORDER_REMOVE_ON_HOLD_START]: assign(_, { loading: true, error: null }),
  [ORDER_REMOVE_ON_HOLD_SUCCESS]: (state, { pubkey, orderUpdate }) => {
    const targetIndex = findIndex({ pubkey })(state.orders);
    return assign(state, {
      loading: false,
      orders: set(targetIndex, orderUpdate)(state.orders),
    });
  },

  [ORDERS_DISPATCHABLE_FETCH_START]: assign(_, { loading: true }),
  [ORDERS_DISPATCHABLE_FETCH_SUCCESS]: (state, action) =>
    assign(state, { orders: action.orders, pages: action.pages, loading: false }),
  [ORDERS_DISPATCHABLE_FETCH_ERROR]: assign(_, { loading: false }),

  [FORMULA_FETCH_START]: state => assign(state, { loading: true, error: null }),
  [FORMULA_FETCH_SUCCESS]: (state, action) =>
    assign(state, { formula: action.formula, loading: false }),
  [FORMULA_FETCH_ERROR]: (state, action) => set('error', action.error.message)(state),
};
export default createReducer(reducerToActionTypeMap, initialState);
