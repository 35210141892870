import React from 'react';
import PropTypes from 'prop-types';
import { forbidExtraProps } from 'airbnb-prop-types';

import { connect } from 'react-redux';

import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import size from 'lodash/fp/size';

import Card from '@mui/material/Card';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

import CenteredProgress from '../Components/CenteredProgress';
import PageRoot from '../Components/PageRoot';

import * as authSelectors from 'dux/auth/selectors';
import * as teammatesSelectors from 'dux/teammates/selectors';
import { useFetchSignedInTeammate } from 'dux/teammates/hooks';

const FlexContainer = styled('div')`
  display: flex;
`;

const StyledCard = styled(Card)`
  padding: 20px;
  min-height: 40px;
`;

const HomeScene = ({ firstName, lastName, username, groups, userId, signedInTeammate }) => {
  const { error } = useFetchSignedInTeammate(userId);

  if (error) throw error;

  if (isEmpty(signedInTeammate)) return <CenteredProgress />;

  // No error and done loading, we can safely consume the props coming from redux
  return (
    <PageRoot>
      <FlexContainer data-testid="welcome-page">
        <StyledCard>
          <Typography>
            Welcome{' '}
            <em>
              {firstName} {lastName}
            </em>{' '}
            !
          </Typography>
          <Typography>
            You are logged in as{' '}
            <code data-testid="welcome-username" style={{ backgroundColor: 'GainsBoro' }}>
              {username}
            </code>
          </Typography>
          <br />
          <Typography data-sentry-unmask>
            You are part of the following group{size(groups) !== 1 ? 's' : ''} :
          </Typography>
          <ul data-testid="welcome-groups" data-sentry-unmask>
            {map(
              ({ name }) => (
                <li key={name}>
                  <Typography>{name}</Typography>
                </li>
              ),
              groups
            )}
          </ul>
        </StyledCard>
      </FlexContainer>
    </PageRoot>
  );
};

HomeScene.propTypes = forbidExtraProps({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  username: PropTypes.string,
  groups: PropTypes.arrayOf(PropTypes.shape({})),
  userId: PropTypes.string,
  signedInTeammate: PropTypes.shape({
    created_at: PropTypes.string,
    display_name: PropTypes.string,
    first_name: PropTypes.string,
    gender: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
    is_active: PropTypes.bool,
    is_staff: PropTypes.bool,
    is_superuser: PropTypes.bool,
    last_name: PropTypes.string,
    middle_name: PropTypes.string,
    permissions: PropTypes.arrayOf(PropTypes.string),
    production_lane_users: PropTypes.arrayOf(PropTypes.string),
    pubkey: PropTypes.string,
    type: PropTypes.string,
    updated_at: PropTypes.string,
    username: PropTypes.string,
  }),
});

HomeScene.defaultProps = {
  firstName: null,
  lastName: null,
  username: null,
  groups: [],
  userId: null,
  signedInTeammate: null,
};

export default connect(
  state => ({
    firstName: teammatesSelectors.getSignedInTeammateFirstName(state),
    lastName: teammatesSelectors.getSignedInTeammateLastName(state),
    username: teammatesSelectors.getSignedInTeammateUsername(state),
    groups: teammatesSelectors.getSignedInTeammateGroups(state),
    signedInTeammate: teammatesSelectors.getSignedInTeammate(state),
    userId: authSelectors.getUserId(state),
  }),
  {}
)(HomeScene);
