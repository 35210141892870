import { createSlice } from '@reduxjs/toolkit';

import type { BufferSpacesState } from './types/slice-types';
import { fetchBufferBins, fetchBufferSpaces } from './thunks';

const initialState: BufferSpacesState = {
  bufferSpaces: [],
  bufferBins: null,
  status: 'idle',
  error: null,
};

const bufferSpacesSlice = createSlice({
  name: 'bufferSpaces',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // Fetch bufferSpaces
      .addCase(fetchBufferSpaces.pending, draftState => {
        draftState.status = 'pending';
        draftState.bufferSpaces = [];
        draftState.error = null;
      })
      .addCase(fetchBufferSpaces.fulfilled, (draftState, action) => {
        const bufferSpaces = action.payload;

        draftState.status = 'fulfilled';
        draftState.bufferSpaces = bufferSpaces;
      })
      .addCase(fetchBufferSpaces.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      // Fetch bufferBins
      .addCase(fetchBufferBins.pending, draftState => {
        draftState.bufferBins = [];
      })
      .addCase(fetchBufferBins.fulfilled, (draftState, action) => {
        const bufferBins = action.payload;

        draftState.bufferBins = bufferBins;
      });
  },
});

const { reducer } = bufferSpacesSlice;

export default reducer;
