import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import isNil from 'lodash/fp/isNil';

import { createSelector } from 'reselect';

import * as flagFetchStatuses from './constants/flagFetchStatus';
import initialState from './initialState';

// Get state.featureFlags
const getFeatureFlagsSubState = getOr(initialState, 'featureFlags');
// Get state.featureFlags.flags
const getFlags = createSelector(getFeatureFlagsSubState, get('flags'));

// Get state.featureFlags.flagFetchStatus
const getFlagsFetchStatus = createSelector(getFeatureFlagsSubState, get('flagFetchStatus'));
// Get state.featureFlags.flagFetchStatus === USER_CONTEXT_SPECIFIC_FAIL || USER_CONTEXT_SPECIFIC_SUCCESS
export const isFlagsDoneFetching = createSelector(
  getFlagsFetchStatus,
  flagFetchStatus =>
    flagFetchStatus === flagFetchStatuses.USER_CONTEXT_SPECIFIC_FAIL ||
    flagFetchStatus === flagFetchStatuses.USER_CONTEXT_SPECIFIC_SUCCESS
);
// Get state.featureFlags.flags.pop-up-country
const getPopUpCountryFlag = createSelector(getFlags, get('pop-up-country'));
// Get state.featureFlags.flags.pop-up-country.active
const isPopUpCountryFlagActive = createSelector(getPopUpCountryFlag, get('active'));
// Get state.featureFlags.flags.pop-up-country.variant
const getPopUpCountryFlagVariant = createSelector(getPopUpCountryFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForPopUpCountry = createSelector(
  isPopUpCountryFlagActive,
  getPopUpCountryFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowPopUpCountry = createSelector(
  getActiveOrVariantForPopUpCountry,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);

// Get state.featureFlags.flags.data-only-ui
const getDataOnlyUiFlag = createSelector(getFlags, get('data-only-ui'));
// Get state.featureFlags.flags.data-only-ui.active
const isDataOnlyUiFlagActive = createSelector(getDataOnlyUiFlag, get('active'));
// Get state.featureFlags.flags.data-only-ui.variant
const getDataOnlyUiFlagVariant = createSelector(getDataOnlyUiFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForDataOnlyUi = createSelector(
  isDataOnlyUiFlagActive,
  getDataOnlyUiFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowDataOnlyUi = createSelector(
  getActiveOrVariantForDataOnlyUi,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);

// Get state.featureFlags.flags.selfies-error-detection
const getSelfiesErrorDetectionFlag = createSelector(getFlags, get('selfies-error-detection'));
// Get state.featureFlags.flags.selfies-error-detection.active
const isSelfiesErrorDetectionFlagActive = createSelector(
  getSelfiesErrorDetectionFlag,
  get('active')
);
// Get state.featureFlags.flags.selfies-error-detection.variant
const getSelfiesErrorDetectionFlagVariant = createSelector(
  getSelfiesErrorDetectionFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForSelfiesErrorDetection = createSelector(
  isSelfiesErrorDetectionFlagActive,
  getSelfiesErrorDetectionFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowSelfiesErrorDetection = createSelector(
  getActiveOrVariantForSelfiesErrorDetection,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);
// Get state.featureFlags.flags.selfies
const getSelfiesFlag = createSelector(getFlags, get('selfies'));
// Get state.featureFlags.flags.selfies.active
const isSelfiesFlagActive = createSelector(getSelfiesFlag, get('active'));
// Get state.featureFlags.flags.selfies.variant
const getSelfiesFlagVariant = createSelector(getSelfiesFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForSelfies = createSelector(
  isSelfiesFlagActive,
  getSelfiesFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowSelfies = createSelector(getActiveOrVariantForSelfies, activeOrVariant =>
  activeOrVariant === 'on' || activeOrVariant === 'off' ? activeOrVariant === 'on' : activeOrVariant
);
// Get state.featureFlags.flags.dispatch-modal-box-status
const getDispatchModalBoxStatusFlag = createSelector(getFlags, get('dispatch-modal-box-status'));
// Get state.featureFlags.flags.dispatch-modal-box-status.active
const isDispatchModalBoxStatusFlagActive = createSelector(
  getDispatchModalBoxStatusFlag,
  get('active')
);
// Get state.featureFlags.flags.dispatch-modal-box-status.variant
const getDispatchModalBoxStatusFlagVariant = createSelector(
  getDispatchModalBoxStatusFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForDispatchModalBoxStatus = createSelector(
  isDispatchModalBoxStatusFlagActive,
  getDispatchModalBoxStatusFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowDispatchModalBoxStatus = createSelector(
  getActiveOrVariantForDispatchModalBoxStatus,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);
// Get state.featureFlags.flags.microhold-palletization
const getMicroholdPalletizationFlag = createSelector(getFlags, get('microhold-palletization'));
// Get state.featureFlags.flags.microhold-palletization.active
const isMicroholdPalletizationFlagActive = createSelector(
  getMicroholdPalletizationFlag,
  get('active')
);
// Get state.featureFlags.flags.microhold-palletization.variant
const getMicroholdPalletizationFlagVariant = createSelector(
  getMicroholdPalletizationFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForMicroholdPalletization = createSelector(
  isMicroholdPalletizationFlagActive,
  getMicroholdPalletizationFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);

export const shouldShowMicroholdPalletizationPhase1 = createSelector(
  getActiveOrVariantForMicroholdPalletization,
  activeOrVariant => activeOrVariant === 'phase-1'
);

export const shouldShowMicroholdPalletizationPhase2 = createSelector(
  getActiveOrVariantForMicroholdPalletization,
  activeOrVariant => activeOrVariant === 'phase-2'
);

export const shouldShowMicroholdPalletizationPhase3 = createSelector(
  getActiveOrVariantForMicroholdPalletization,
  activeOrVariant => activeOrVariant === 'phase-3'
);

export const shouldShowMicroholdPalletizationPhase4 = createSelector(
  getActiveOrVariantForMicroholdPalletization,
  activeOrVariant => activeOrVariant === 'phase-4'
);

export const shouldShowMicroholdPalletizationPhase5 = createSelector(
  getActiveOrVariantForMicroholdPalletization,
  activeOrVariant => activeOrVariant === 'phase-5'
);

export const shouldShowMicroholdPalletizationPhase = createSelector(
  [
    shouldShowMicroholdPalletizationPhase1,
    shouldShowMicroholdPalletizationPhase2,
    shouldShowMicroholdPalletizationPhase3,
    shouldShowMicroholdPalletizationPhase4,
    shouldShowMicroholdPalletizationPhase5,
  ],
  (phase1, phase2, phase3, phase4, phase5) => phase1 || phase2 || phase3 || phase4 || phase5
);
// Get state.featureFlags.flags.accessory-gwp
const getAccessoryGwpFlag = createSelector(getFlags, get('accessory-gwp'));
// Get state.featureFlags.flags.accessory-gwp.active
const isAccessoryGwpFlagActive = createSelector(getAccessoryGwpFlag, get('active'));
// Get state.featureFlags.flags.accessory-gwp.variant
const getAccessoryGwpFlagVariant = createSelector(getAccessoryGwpFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForAccessoryGwp = createSelector(
  isAccessoryGwpFlagActive,
  getAccessoryGwpFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowAccessoryGwp = createSelector(
  getActiveOrVariantForAccessoryGwp,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);
// Get state.featureFlags.flags.short-polling-download-pdf-documents
const getShortPollingDownloadPdfDocumentsFlag = createSelector(
  getFlags,
  get('short-polling-download-pdf-documents')
);
// Get state.featureFlags.flags.short-polling-download-pdf-documents.active
const isShortPollingDownloadPdfDocumentsFlagActive = createSelector(
  getShortPollingDownloadPdfDocumentsFlag,
  get('active')
);
// Get state.featureFlags.flags.short-polling-download-pdf-documents.variant
const getShortPollingDownloadPdfDocumentsFlagVariant = createSelector(
  getShortPollingDownloadPdfDocumentsFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForShortPollingDownloadPdfDocuments = createSelector(
  isShortPollingDownloadPdfDocumentsFlagActive,
  getShortPollingDownloadPdfDocumentsFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowShortPollingDownloadPdfDocuments = createSelector(
  getActiveOrVariantForShortPollingDownloadPdfDocuments,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);
// Get state.featureFlags.flags.multi-lane-improvements
const getMultiLaneImprovementsFlag = createSelector(getFlags, get('multi-lane-improvements'));
// Get state.featureFlags.flags.multi-lane-improvements.active
const isMultiLaneImprovementsFlagActive = createSelector(
  getMultiLaneImprovementsFlag,
  get('active')
);
// Get state.featureFlags.flags.multi-lane-improvements.variant
const getMultiLaneImprovementsFlagVariant = createSelector(
  getMultiLaneImprovementsFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForMultiLaneImprovements = createSelector(
  isMultiLaneImprovementsFlagActive,
  getMultiLaneImprovementsFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowMultiLaneImprovements = createSelector(
  getActiveOrVariantForMultiLaneImprovements,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);
