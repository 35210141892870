import { union } from 'lodash/array';
import add from 'lodash/fp/add';
import assign from 'lodash/fp/assign';
import filter from 'lodash/fp/filter';
import findIndex from 'lodash/fp/findIndex';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import map from 'lodash/fp/map';
import remove from 'lodash/fp/remove';
import set from 'lodash/fp/set';

import createReducer from 'utils/reduxUtils';

import {
  PRODUCTION_CLEAR_ERROR,
  PRODUCTION_CLEAR_FIX,
  PRODUCTION_CLEAR_SUB_ERROR_MESSAGES,
  PRODUCTION_DISPATCH_DESELECT_LANE,
  PRODUCTION_DISPATCH_SELECT_LANE,
  PRODUCTION_DISPATCH_SET_SELECTED_LANES,
  PRODUCTION_DONE_FOCUS_QR_CODE_SCANNING_FIELD,
  PRODUCTION_DOWNLOAD_COLLATERALS_ERROR,
  PRODUCTION_DOWNLOAD_COLLATERALS_START,
  PRODUCTION_DOWNLOAD_POLLING_COLLATERALS_SUCCESS,
  PRODUCTION_DOWNLOAD_SYNC_COLLATERALS_SUCCESS,
  PRODUCTION_EMPTY_BIN_SUCCESS,
  PRODUCTION_EMPTY_BOX,
  PRODUCTION_FETCH_BOX_ERROR,
  PRODUCTION_FETCH_BOX_FROM_BIN_ERROR,
  PRODUCTION_FETCH_BOX_FROM_BIN_START,
  PRODUCTION_FETCH_BOX_FROM_BIN_SUCCESS,
  PRODUCTION_FETCH_BOX_START,
  PRODUCTION_FETCH_BOX_SUCCESS,
  PRODUCTION_FETCH_LANES_ERROR,
  PRODUCTION_FETCH_LANES_START,
  PRODUCTION_FETCH_LANES_SUCCESS,
  PRODUCTION_FETCH_NEXT_BOX_FROM_LANE_ERROR,
  PRODUCTION_FETCH_NEXT_BOX_FROM_LANE_START,
  PRODUCTION_FETCH_NEXT_BOX_FROM_LANE_SUCCESS,
  PRODUCTION_FETCH_PREPACK_LAB_SUCCESS,
  PRODUCTION_LABELING_ON_SCAN_PRODUCT,
  PRODUCTION_MANUAL_START_BOX_ERROR,
  PRODUCTION_MANUAL_START_BOX_START,
  PRODUCTION_MANUAL_START_BOX_SUCCESS,
  PRODUCTION_ON_PRINT_PREPACK_ERROR,
  PRODUCTION_ON_PRINT_PREPACK_START,
  PRODUCTION_ON_PRINT_PREPACK_SUCCESS,
  PRODUCTION_ON_SCAN_COMPLEMENTARY_ITEM,
  PRODUCTION_ON_SCAN_FORMULA_ERROR,
  PRODUCTION_ON_SCAN_FORMULA_START,
  PRODUCTION_ON_SCAN_FORMULA_SUCCESS,
  PRODUCTION_ON_SCAN_PALLET_BOX_ERROR,
  PRODUCTION_ON_SCAN_PALLET_BOX_START,
  PRODUCTION_ON_SCAN_PALLET_BOX_SUCCESS,
  PRODUCTION_ON_SCAN_PALLET_ERROR,
  PRODUCTION_ON_SCAN_PALLET_START,
  PRODUCTION_ON_SCAN_PALLET_SUCCESS,
  PRODUCTION_ON_SCAN_PRODUCT_ERROR,
  PRODUCTION_ON_SCAN_PRODUCT_SUCCESS,
  PRODUCTION_ON_SCAN_VARIANT,
  PRODUCTION_ON_UPDATE_STATUS_SUCCESS,
  PRODUCTION_PALLET_CREATE_ERROR,
  PRODUCTION_PALLET_CREATE_START,
  PRODUCTION_PALLET_CREATE_SUCCESS,
  PRODUCTION_PALLET_FETCH_ERROR,
  PRODUCTION_PALLET_FETCH_START,
  PRODUCTION_PALLET_FETCH_SUCCESS,
  PRODUCTION_PALLET_STATUS_UPDATE_ERROR,
  PRODUCTION_PALLET_STATUS_UPDATE_START,
  PRODUCTION_PALLET_STATUS_UPDATE_SUCCESS,
  PRODUCTION_PALLETIZE_RESET_SUCCESS,
  PRODUCTION_PROD_ITEMS_UPDATE_ERROR,
  PRODUCTION_PROD_ITEMS_UPDATE_NEXT_STATUS_ERROR,
  PRODUCTION_PROD_ITEMS_UPDATE_NEXT_STATUS_START,
  PRODUCTION_PROD_ITEMS_UPDATE_NEXT_STATUS_SUCCESS,
  PRODUCTION_PROD_ITEMS_UPDATE_START,
  PRODUCTION_PROD_ITEMS_UPDATE_SUCCESS,
  PRODUCTION_QUALITY_TEST_CREATE_ERROR,
  PRODUCTION_QUALITY_TEST_CREATE_START,
  PRODUCTION_QUALITY_TEST_CREATE_SUCCESS,
  PRODUCTION_QUALITY_TEST_GET_ERROR,
  PRODUCTION_QUALITY_TEST_GET_START,
  PRODUCTION_QUALITY_TEST_GET_SUCCESS,
  PRODUCTION_QUALITY_TEST_STATUS_UPDATE_ERROR,
  PRODUCTION_QUALITY_TEST_STATUS_UPDATE_START,
  PRODUCTION_QUALITY_TEST_STATUS_UPDATE_SUCCESS,
  PRODUCTION_REMOVE_BOX_PALLET_ERROR,
  PRODUCTION_REMOVE_BOX_PALLET_START,
  PRODUCTION_REMOVE_BOX_PALLET_SUCCESS,
  PRODUCTION_RESET_SCAN,
  PRODUCTION_SEARCH_BOX_ERROR,
  PRODUCTION_SEARCH_BOX_START,
  PRODUCTION_SEARCH_BOX_SUCCESS,
  PRODUCTION_SET_ACTION,
  PRODUCTION_SET_BIN,
  PRODUCTION_SET_BOXES_ERROR,
  PRODUCTION_SET_BOXES_START,
  PRODUCTION_SET_BOXES_SUCCESS,
  PRODUCTION_SET_COMPLEMENTARY_ITEMS,
  PRODUCTION_SET_ERROR_MESSAGE,
  PRODUCTION_SET_FIX_MESSAGE,
  PRODUCTION_SET_FOCUS_QR_CODE_SCANNING_FIELD,
  PRODUCTION_SET_LANE,
  PRODUCTION_SET_LOADING_FALSE,
  PRODUCTION_SET_LOADING_TRUE,
  PRODUCTION_SET_NEXT_PREVIEW,
  PRODUCTION_SET_SHIPPING_LABEL_SCANNED,
  PRODUCTION_SET_SUB_ERROR_MESSAGES,
  PRODUCTION_SET_SUCCESS_MESSAGE,
  PRODUCTION_SET_VARIANT_SCANNED_PUBKEY_QUANTITY_IN_BOX,
} from './actionTypes';
import initialState from './initialState';

const reducerToActionTypeMap = {
  [PRODUCTION_SET_FOCUS_QR_CODE_SCANNING_FIELD]: set('shouldFocusQrCodeField', true),
  [PRODUCTION_DONE_FOCUS_QR_CODE_SCANNING_FIELD]: set('shouldFocusQrCodeField', false),
  [PRODUCTION_SET_LOADING_TRUE]: state => set('loading', true)(state),
  [PRODUCTION_SET_LOADING_FALSE]: state => set('loading', false)(state),
  [PRODUCTION_FETCH_NEXT_BOX_FROM_LANE_ERROR]: state => set('loading', false)(state),
  [PRODUCTION_FETCH_NEXT_BOX_FROM_LANE_SUCCESS]: (state, action) =>
    assign(state, {
      nextBoxInLane: action.nextBoxInLane,
      loading: false,
    }),
  [PRODUCTION_PROD_ITEMS_UPDATE_SUCCESS]: state => set('loading', false)(state),
  [PRODUCTION_FETCH_LANES_START]: state => set('loading', true)(state),
  [PRODUCTION_FETCH_LANES_SUCCESS]: (state, action) =>
    flow(set('loading', false), set('lanes', action.lanes))(state),
  [PRODUCTION_FETCH_LANES_ERROR]: (state, action) => set('error', action.error)(state),
  [PRODUCTION_FETCH_BOX_FROM_BIN_ERROR]: (state, action) => set('error', action.error)(state),
  [PRODUCTION_PROD_ITEMS_UPDATE_ERROR]: (state, action) => set('error', action.error)(state),
  [PRODUCTION_FETCH_BOX_FROM_BIN_START]: state => set('loading', true)(state),
  [PRODUCTION_FETCH_BOX_FROM_BIN_SUCCESS]: state => set('loading', false)(state),
  [PRODUCTION_FETCH_NEXT_BOX_FROM_LANE_START]: state => set('loading', true)(state),
  [PRODUCTION_PROD_ITEMS_UPDATE_START]: state => set('loading', true)(state),
  [PRODUCTION_SET_NEXT_PREVIEW]: (state, action) => set('nextPreview', action.nextPreview)(state),

  [PRODUCTION_FETCH_PREPACK_LAB_SUCCESS]: (state, action) =>
    flow(set('loading', false), set('prepackLabs', action.prepackLab))(state), // actually tested as is (singular assigned to plural)
  [PRODUCTION_SET_ACTION]: (state, action) => set('details.action', action.action)(state),
  [PRODUCTION_SET_LANE]: (state, action) => set('details.lane', action.lane)(state),
  [PRODUCTION_FETCH_BOX_ERROR]: (state, action) => set('error', action.error)(state),
  [PRODUCTION_SET_BOXES_START]: state => set('loading', true)(state),
  [PRODUCTION_SET_BOXES_SUCCESS]: (state, action) =>
    assign(state, { boxes: action.boxes, pages: action.pages, loading: false }),
  [PRODUCTION_SET_BOXES_ERROR]: (state, action) =>
    assign(state, { error: action.error, loading: false }),
  [PRODUCTION_SEARCH_BOX_START]: set('loading', true),
  [PRODUCTION_SEARCH_BOX_SUCCESS]: (state, action) =>
    assign(state, { boxes: action.boxes, pages: action.pages, loading: false }),
  [PRODUCTION_SEARCH_BOX_ERROR]: (state, action) =>
    assign(state, { error: action.error, loading: false }),

  [PRODUCTION_MANUAL_START_BOX_START]: state =>
    flow(set('loading', true), set('error', null))(state),
  [PRODUCTION_MANUAL_START_BOX_SUCCESS]: state =>
    flow(set('loading', false), set('error', null))(state),
  [PRODUCTION_MANUAL_START_BOX_ERROR]: (state, action) =>
    flow(set('loading', false), set('error', action.error))(state),
  [PRODUCTION_FETCH_BOX_START]: state => set('loading', true)(state),
  [PRODUCTION_FETCH_BOX_SUCCESS]: (state, action) =>
    flow(
      set('loading', false),
      set('details.box', action.box),
      set('details.box.complementaryItems', [])
    )(state),
  [PRODUCTION_EMPTY_BOX]: state =>
    flow(
      set('details.box', null),
      set('details.scannedPubkeys', []),
      set('details.variantsScannedPubkey', []),
      set('details.scannedShippingLabel', false),
      set('details.lane', '')
    )(state),
  [PRODUCTION_RESET_SCAN]: state =>
    flow(
      set('details.scannedPubkeys', []),
      set('details.variantsScannedPubkey', []),
      set('details.scannedShippingLabel', false)
    )(state),
  [PRODUCTION_SET_COMPLEMENTARY_ITEMS]: (state, action) =>
    set('details.box.complementaryItems', action.complementaryItems)(state),
  [PRODUCTION_ON_PRINT_PREPACK_START]: state => set('loading', true)(state),
  [PRODUCTION_ON_PRINT_PREPACK_ERROR]: state => set('loading', false)(state),
  [PRODUCTION_ON_PRINT_PREPACK_SUCCESS]: state => set('loading', false)(state),
  [PRODUCTION_ON_SCAN_PRODUCT_SUCCESS]: (state, action) => {
    const t = getOr([], 'details.scannedPubkeys')(state);
    return set('details.scannedPubkeys', t.concat(action.pubkey))(state);
  },
  [PRODUCTION_LABELING_ON_SCAN_PRODUCT]: state => set('details.scannedPubkeys', [])(state),
  [PRODUCTION_ON_SCAN_PRODUCT_ERROR]: (state, action) => set('error', action.error)(state),
  [PRODUCTION_CLEAR_ERROR]: state => set('error', null)(state),
  [PRODUCTION_CLEAR_SUB_ERROR_MESSAGES]: state => set('subErrorMessages', null)(state),
  [PRODUCTION_SET_VARIANT_SCANNED_PUBKEY_QUANTITY_IN_BOX]: (state, action) =>
    set('details.variantsScannedPubkey', action.variantScannedQuantity)(state),
  [PRODUCTION_ON_SCAN_VARIANT]: (state, action) => {
    const index = findIndex({ pubkey: action.pubkey }, state.details.variantsScannedPubkey);
    return set(`details.variantsScannedPubkey[${index}].scannedQuantity`, action.quantity)(state);
  },
  [PRODUCTION_ON_SCAN_COMPLEMENTARY_ITEM]: (state, action) =>
    flow(
      get('details.box.complementaryItems'),
      map(ci => {
        if (get('item_object.pubkey', ci) !== get('pubkey', action)) {
          return ci;
        }
        return assign(ci, { scannedQuantity: add(get('scannedQuantity', ci), 1) });
      }),
      set('details.box.complementaryItems')
    )(state),
  [PRODUCTION_SET_BIN]: (state, action) =>
    set('details.box.buffer_bin', {
      pubkey: action.pubkey,
      coordinates: action.coordinates,
    })(state),
  [PRODUCTION_SET_SUCCESS_MESSAGE]: (state, action) =>
    set('success', {
      message: action.message,
      severity: 'success',
    })(state),
  [PRODUCTION_EMPTY_BIN_SUCCESS]: state =>
    set('success', { message: null, severity: 'info' })(state),
  [PRODUCTION_ON_UPDATE_STATUS_SUCCESS]: (state, action) => {
    const index = flow(
      get('details.box.proditems'),
      findIndex(pi => pi.pubkey === action.proditem.pubkey)
    )(state);
    return set(`details.box.proditems[${index}].status`, action.proditem.status)(state);
  },
  [PRODUCTION_SET_ERROR_MESSAGE]: (state, action) => set('error', action.error)(state),
  [PRODUCTION_SET_SUB_ERROR_MESSAGES]: (state, action) =>
    set('subErrorMessages', action.messages)(state),
  [PRODUCTION_SET_SHIPPING_LABEL_SCANNED]: state =>
    set('details.scannedShippingLabel', true)(state),
  [PRODUCTION_CLEAR_FIX]: state => set('fix', null)(state),
  [PRODUCTION_SET_FIX_MESSAGE]: (state, action) => set('fix', action.message)(state),
  [PRODUCTION_ON_SCAN_FORMULA_START]: state => set('loading', true)(state),
  [PRODUCTION_ON_SCAN_FORMULA_SUCCESS]: (state, action) => {
    const t = getOr([], 'details.scannedPubkeys')(state);
    return flow(
      set('loading', false),
      set('details.scannedPubkeys', t.concat(action.pubkey))
    )(state);
  },
  [PRODUCTION_ON_SCAN_FORMULA_ERROR]: (state, action) =>
    flow(set('loading', false), set('error', action.error))(state),
  [PRODUCTION_PROD_ITEMS_UPDATE_NEXT_STATUS_ERROR]: (state, action) =>
    set('error', action.error)(state),
  [PRODUCTION_PROD_ITEMS_UPDATE_NEXT_STATUS_START]: state => set('loading', true)(state),
  [PRODUCTION_PROD_ITEMS_UPDATE_NEXT_STATUS_SUCCESS]: state => set('loading', false)(state),
  [PRODUCTION_QUALITY_TEST_CREATE_ERROR]: (state, action) =>
    assign(state, { error: action.err, loading: false }),
  [PRODUCTION_QUALITY_TEST_CREATE_START]: state => set('loading', true)(state),
  [PRODUCTION_QUALITY_TEST_CREATE_SUCCESS]: state => set('loading', false)(state),
  [PRODUCTION_QUALITY_TEST_GET_ERROR]: (state, action) =>
    assign(state, { error: action.err, loading: false }),
  [PRODUCTION_QUALITY_TEST_GET_START]: state => set('loading', true)(state),
  [PRODUCTION_QUALITY_TEST_GET_SUCCESS]: (state, action) =>
    assign(state, {
      microhold: {
        quality_tests: action.qualityTests,
        pages: { prev: action.previousPage, next: action.nextPage },
      },
      loading: false,
    }),
  [PRODUCTION_QUALITY_TEST_STATUS_UPDATE_ERROR]: (state, action) =>
    assign(state, { error: action.err, loading: false }),
  [PRODUCTION_QUALITY_TEST_STATUS_UPDATE_START]: state => set('loading', true)(state),
  [PRODUCTION_QUALITY_TEST_STATUS_UPDATE_SUCCESS]: state => set('loading', false)(state),

  [PRODUCTION_DOWNLOAD_COLLATERALS_START]: (state, action) => {
    const updatedIsLoading = set([action.isLoading], true, {
      ...state.globalCollaterals?.isLoading,
    });
    const updatedLoadingLabels = (state.globalCollaterals?.loadingLabels || []).concat(
      action.labelsLane
    );
    return assign(state, {
      globalCollaterals: {
        isLoading: updatedIsLoading,
        // we keep a list of lanes that we're currently downloading labels from as there is 1 button/lane
        loadingLabels: updatedLoadingLabels,
      },
    });
  },
  [PRODUCTION_DOWNLOAD_POLLING_COLLATERALS_SUCCESS]: (state, action) => {
    const updatedIsLoading = set([action.isLoading], false, {
      ...state.globalCollaterals?.isLoading,
    });
    const updatedLabelsLanes = filter(item => item !== action.labelsLane, state.labelsLanes || []);
    return assign(state, {
      globalCollaterals: {
        isLoading: updatedIsLoading,
        loadingLabels: updatedLabelsLanes,
      },
    });
  },
  [PRODUCTION_DOWNLOAD_SYNC_COLLATERALS_SUCCESS]: (state, action) => {
    const updatedIsLoading = set([action.isLoading], false, {
      ...state.globalCollaterals?.isLoading,
    });
    const updatedLabelsLanes = filter(item => item !== action.labelsLane, state.labelsLanes || []);
    return assign(state, {
      globalCollaterals: {
        isLoading: updatedIsLoading,
        loadingLabels: updatedLabelsLanes,
      },
    });
  },
  [PRODUCTION_DOWNLOAD_COLLATERALS_ERROR]: (state, action) => {
    const updatedIsLoading = set([action.isLoading], false, {
      ...state.globalCollaterals?.isLoading,
    });
    const updatedLabelsLanes = filter(item => item !== action.labelsLane, state.labelsLanes || []);
    return assign(state, {
      globalCollaterals: {
        isLoading: updatedIsLoading,
        loadingLabels: updatedLabelsLanes,
      },
      error: action.error,
    });
  },
  [PRODUCTION_PALLET_CREATE_ERROR]: (state, action) =>
    assign(state, { error: action.error, loading: false }),
  [PRODUCTION_PALLET_CREATE_START]: state => set('loading', true)(state),
  [PRODUCTION_PALLET_CREATE_SUCCESS]: state => set('loading', false)(state),
  [PRODUCTION_PALLET_FETCH_ERROR]: (state, action) =>
    assign(state, { error: action.error, loading: false }),
  [PRODUCTION_PALLET_FETCH_START]: state => set('loading', true)(state),
  [PRODUCTION_PALLET_FETCH_SUCCESS]: (state, action) =>
    assign(state, {
      microhold: {
        pallets: action.pallets,
        pages: { prev: action.previousPage, next: action.nextPage },
      },
      loading: false,
    }),
  [PRODUCTION_PALLET_STATUS_UPDATE_ERROR]: (state, action) =>
    assign(state, { error: action.error, loading: false }),
  [PRODUCTION_PALLET_STATUS_UPDATE_START]: state => set('loading', true)(state),
  [PRODUCTION_PALLET_STATUS_UPDATE_SUCCESS]: (state, action) =>
    assign(state, {
      success: {
        message: `Successfully ${action.status} the pallet`,
        severity: 'success',
      },
      loading: false,
    }),
  [PRODUCTION_ON_SCAN_PALLET_ERROR]: (state, action) =>
    assign(state, {
      error: action.error,
      loading: false,
    }),
  [PRODUCTION_ON_SCAN_PALLET_START]: state => set('loading', true)(state),
  [PRODUCTION_ON_SCAN_PALLET_SUCCESS]: state => set('loading', false)(state),
  [PRODUCTION_ON_SCAN_PALLET_BOX_ERROR]: (state, action) =>
    assign(state, {
      error: action.error,
      loading: false,
    }),
  [PRODUCTION_ON_SCAN_PALLET_BOX_START]: state => set('loading', true)(state),
  [PRODUCTION_ON_SCAN_PALLET_BOX_SUCCESS]: (state, action) =>
    assign(state, {
      microhold: {
        pallet: action.pallet,
      },
      success: {
        message: 'Successfully palletized the box',
        severity: 'success',
      },
      loading: false,
    }),
  [PRODUCTION_PALLET_FETCH_ERROR]: (state, action) =>
    assign(state, {
      error: action.error,
      loading: false,
    }),
  [PRODUCTION_PALLET_FETCH_START]: state => set('loading', true)(state),
  [PRODUCTION_PALLET_FETCH_SUCCESS]: (state, action) =>
    assign(state, {
      microhold: {
        pallet: action.pallet,
        pallets: action.pallets,
        pages: {
          prev: action.previousPage,
          next: action.nextPage,
          first: action.firstPage,
          last: action.lastPage,
        },
      },
      loading: false,
    }),
  [PRODUCTION_PALLETIZE_RESET_SUCCESS]: state =>
    assign(state, {
      microhold: {
        pallet: null,
      },
      loading: false,
    }),
  [PRODUCTION_REMOVE_BOX_PALLET_ERROR]: (state, action) =>
    assign(state, {
      error: action.error,
      loading: false,
    }),
  [PRODUCTION_REMOVE_BOX_PALLET_START]: state => set('loading', true)(state),
  [PRODUCTION_REMOVE_BOX_PALLET_SUCCESS]: (state, action) =>
    assign(state, {
      microhold: {
        pallet: action.pallet,
      },
      success: {
        message: action.success,
        severity: 'success',
      },
      loading: false,
    }),
  [PRODUCTION_DISPATCH_SELECT_LANE]: (state, action) =>
    assign(state, {
      dispatch: {
        ...state.dispatch,
        selectedLanes: union(state.dispatch.selectedLanes, [action.pubkey]),
      },
    }),
  [PRODUCTION_DISPATCH_DESELECT_LANE]: (state, action) =>
    assign(state, {
      dispatch: {
        ...state.dispatch,
        selectedLanes: remove(val => action.pubkey === val, state.dispatch.selectedLanes),
      },
    }),
  [PRODUCTION_DISPATCH_SET_SELECTED_LANES]: (state, action) =>
    assign(state, {
      dispatch: {
        ...state.dispatch,
        selectedLanes: [...action.pubkeys],
      },
    }),
};
export default createReducer(reducerToActionTypeMap, initialState);
