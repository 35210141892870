import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import assign from 'lodash/fp/assign';
import map from 'lodash/fp/map';
import _ from 'lodash/fp/placeholder';

import useSWR from 'swr';

import initApiClient from 'services/ApiClient';

import logSentryError from 'utils/sentry';

import {
  TEAMMATES_FETCH_SIGNED_IN_TEAMMATE_ERROR,
  TEAMMATES_FETCH_SIGNED_IN_TEAMMATE_START,
  TEAMMATES_FETCH_SIGNED_IN_TEAMMATE_SUCCESS,
  TEAMMATES_FETCH_TEAMMATES_SUCCESS,
  TEAMMATES_FETCH_TEAMMATES_SUCCESS_DATA,
  TEAMMATES_LOAD_END,
  TEAMMATES_LOAD_START,
} from './actionTypes';

export const useFetchTeammates = urlToFetch => {
  const dispatch = useDispatch();
  const APIClient = initApiClient(dispatch);
  // The fetch Strategy
  const { data, error, ...ret } = useSWR(
    `useFetchTeammates(${urlToFetch})`,
    async () => {
      const query = await APIClient.get(urlToFetch);
      const result = await query.json();
      const teammates = map(assign(_, { mode: 'production' }))(result);
      return { teammates, query };
    },
    { revalidateOnFocus: false }
  );
  // The persist in store strategy
  useEffect(() => {
    if (!data && !error) {
      dispatch({ type: TEAMMATES_LOAD_START });
      return;
    }

    if (error) {
      logSentryError('[Teammates Hooks] fetch production teammates', error);
      return;
    }

    if (data) {
      dispatch({ type: TEAMMATES_LOAD_END });
      dispatch({ type: TEAMMATES_FETCH_TEAMMATES_SUCCESS_DATA, pages: data.query.pages });
      dispatch({ type: TEAMMATES_FETCH_TEAMMATES_SUCCESS, teammates: data.teammates });
    }
  }, [dispatch, data, error]);
  // Return strategy: Swallow the data to ensure we use the data from redux store (Single source of truth pattern)
  return { isLoading: !data && !error, error, ...ret };
};

export const useFetchSignedInTeammate = userId => {
  const dispatch = useDispatch();
  const urlToFetch = `/v1/backoffice/teammates/${userId}`;
  const APIClient = initApiClient(dispatch);
  // The fetch Strategy
  const { data, error, ...ret } = useSWR(
    `useFetchSignedInTeammate(${urlToFetch})`,
    async () => {
      const query = await APIClient.get(urlToFetch);
      const signedInTeammate = await query.json();

      return { signedInTeammate, query };
    },
    { revalidateOnFocus: false }
  );
  // The persist in store strategy
  useEffect(() => {
    if (!data && !error) {
      dispatch({ type: TEAMMATES_FETCH_SIGNED_IN_TEAMMATE_START });
      return;
    }

    if (error) {
      dispatch({ type: TEAMMATES_FETCH_SIGNED_IN_TEAMMATE_ERROR, error });
      logSentryError('[Teammates Hooks] fetch signedIn teammate', error);
      return;
    }

    if (data) {
      dispatch({
        type: TEAMMATES_FETCH_SIGNED_IN_TEAMMATE_SUCCESS,
        signedInTeammate: data.signedInTeammate,
      });
    }
  }, [dispatch, data, error]);
  // Return strategy: Swallow the data to ensure we use the data from redux store (Single source of truth pattern)
  return { isLoading: !data && !error, error, ...ret };
};
