import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import get from 'lodash/fp/get';

import type { Pages } from 'types/common';

import initApiClient from 'services/ApiClient';

import logSentryError from 'utils/sentry';

import type { CustomerFeedbacks, FeedbacksState, FormulaFeedbacks } from './types';

export const fetchCustomerFeedbacks = createAsyncThunk<CustomerFeedbacks, string>(
  'feedbacks/fetchCustomerFeedbacks',
  async (pubkey, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const response = await APIClient.get(`/v1/backoffice/customers/${pubkey}/feedbacks`);
      const result = await response.json();
      return result;
    } catch (err) {
      logSentryError('[fetchCustomerFeedbacks] fetch customer feedbacks', err);
      throw err;
    }
  }
);

export const fetchFormulaFeedbacks = createAsyncThunk<
  { pages: Pages; feedbacks: FormulaFeedbacks },
  string
>('feedbacks/fetchFormulaFeedbacks', async (urlToFetch, { dispatch }) => {
  try {
    const APIClient = initApiClient(dispatch);
    const query = await APIClient.get(urlToFetch);
    const pages = get('pages', query) as Pages;
    const feedbacks = await query.json();
    return { feedbacks, pages };
  } catch (err) {
    logSentryError('[fetchFormulaFeedbacks] fetch formula feedbacks', err);
    throw err;
  }
});

const initialState: FeedbacksState = {
  status: 'idle',
  feedbacks: [],
  error: null,
  pages: {},
};

const feedbacksSlice = createSlice({
  name: 'feedbacks',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCustomerFeedbacks.pending, draftState => {
        draftState.status = 'pending';
        draftState.feedbacks = [];
        draftState.error = null;
      })
      .addCase(fetchCustomerFeedbacks.fulfilled, (draftState, action) => {
        const feedbacks = action.payload;

        draftState.status = 'fulfilled';
        draftState.feedbacks = feedbacks;
      })
      .addCase(fetchCustomerFeedbacks.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      })
      .addCase(fetchFormulaFeedbacks.pending, draftState => {
        draftState.status = 'pending';
        draftState.feedbacks = [];
        draftState.pages = {};
        draftState.error = null;
      })
      .addCase(fetchFormulaFeedbacks.fulfilled, (draftState, action) => {
        const { feedbacks, pages } = action.payload;

        draftState.status = 'fulfilled';
        draftState.feedbacks = feedbacks;
        draftState.pages = pages;
      })
      .addCase(fetchFormulaFeedbacks.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      });
  },
});

const { reducer } = feedbacksSlice;

export default reducer;
