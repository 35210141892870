import React from 'react';

import assign from 'lodash/fp/assign';
import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import findIndex from 'lodash/fp/findIndex';
import flatMap from 'lodash/fp/flatMap';
import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';
import uniq from 'lodash/fp/uniq';

import SubscriptionIcon from '@mui/icons-material/Autorenew';
import DispatchIcon from '@mui/icons-material/CallSplit';
import ProductionIcon from '@mui/icons-material/Colorize';
import FastConsultationIcon from '@mui/icons-material/FlashOn';
import ExportIcon from '@mui/icons-material/GetApp';
import BufferIcon from '@mui/icons-material/GridOn';
import GroupsIcon from '@mui/icons-material/Groups';
import HelpIcon from '@mui/icons-material/Help';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import FeedbackIcon from '@mui/icons-material/InvertColors';
import CustomerIcon from '@mui/icons-material/Person';
import PrintIcon from '@mui/icons-material/Print';
import GiftIcon from '@mui/icons-material/Redeem';
import OrderIcon from '@mui/icons-material/ShoppingCart';
import { createSelector } from 'reselect';

import navigationPermissionsForGroup from 'utils/teammatesAccess/navigationPermissionsForGroup';
import {
  AUTO_DISPATCH,
  BUFFER_SPACES_LIST,
  CUSTOMER_LIST,
  DATA_PORTAL,
  EXPORTS,
  FAST_CONSULTATION,
  FEEDBACK_LIST,
  GIFT_LIST,
  HELP,
  MANUAL_DISPATCH,
  NEW_FLOW_PRODUCTION_SCREEN,
  OLD_FLOW_PRODUCTION_SCREEN,
  ORDER_LIST,
  ORDER_VIEW,
  PACK_SCREEN,
  PRINT_COLLATERALS,
  PRODUCTION_LIST,
  SUBSCRIPTION_LIST,
  TEAMMATES_DETAIL,
  TEAMMATES_EDIT,
  TEAMMATES_LIST,
} from 'utils/teammatesAccess/teammatesPermissions';

const menuItems = [
  // CX
  {
    name: 'Customers',
    dataTestId: 'nav-customers',
    to: '/customers',
    icon: <CustomerIcon />,
    slug: CUSTOMER_LIST,
    group: 'CX',
  },
  {
    name: 'Orders',
    dataTestId: 'nav-orders',
    to: '/orders',
    icon: <OrderIcon />,
    slug: ORDER_LIST,
    group: 'CX',
  },
  {
    name: 'Subscriptions',
    dataTestId: 'nav-subscriptions',
    to: '/subscriptions',
    icon: <SubscriptionIcon />,
    slug: SUBSCRIPTION_LIST,
    group: 'CX',
  },
  {
    name: 'Feedbacks',
    dataTestId: 'nav-feedbacks',
    to: '/feedbacks',
    icon: <FeedbackIcon />,
    slug: FEEDBACK_LIST,
    group: 'CX',
  },
  {
    name: 'Gifts',
    dataTestId: 'nav-gifts',
    to: '/gifts',
    icon: <GiftIcon />,
    slug: GIFT_LIST,
    group: 'CX',
  },
  // Fulfillment
  {
    name: 'Production',
    dataTestId: 'nav-production',
    to: '/production',
    icon: <ProductionIcon />,
    slug: PRODUCTION_LIST,
    group: 'Fulfillment',
  },
  {
    name: 'Dispatch',
    dataTestId: 'nav-dispatch',
    to: '/production/dispatch/auto',
    icon: <DispatchIcon />,
    slug: AUTO_DISPATCH,
    group: 'Fulfillment',
  },
  {
    name: 'Buffer',
    dataTestId: 'nav-buffer',
    to: '/production/buffer-spaces',
    icon: <BufferIcon />,
    slug: BUFFER_SPACES_LIST,
    group: 'Fulfillment',
  },
  {
    name: 'Print',
    dataTestId: 'nav-print-collaterals',
    to: '/production/print-collaterals',
    icon: <PrintIcon />,
    slug: PRINT_COLLATERALS,
    group: 'Fulfillment',
  },
  // Data Analysis
  {
    name: 'Exports',
    dataTestId: 'nav-exports',
    to: '/exports',
    icon: <ExportIcon />,
    slug: EXPORTS,
    group: 'Data Analysis',
  },
  {
    name: 'Data Portal',
    dataTestId: 'nav-data-portal',
    to: '/dataportal',
    icon: <InsertChartIcon />,
    slug: DATA_PORTAL,
    group: 'Data Analysis',
  },
  // Administrate
  {
    name: 'Users',
    dataTestId: 'nav-teammates',
    to: '/teammates',
    icon: <GroupsIcon />,
    slug: TEAMMATES_LIST,
    group: 'Administration',
  },
  // QA
  {
    name: 'Fast',
    dataTestId: 'nav-fast-consultation',
    to: '/fastconsultation',
    icon: <FastConsultationIcon />,
    slug: FAST_CONSULTATION,
    group: 'Administration',
  },
  // Misc
  {
    name: 'Help',
    dataTestId: 'nav-help',
    to: '/help',
    icon: <HelpIcon />,
    slug: HELP,
    group: 'Misc',
  },
];

const getTeammatesState = state => state.teammates;

export const getTeammates = createSelector(getTeammatesState, get('teammates'));
export const getLoading = createSelector(getTeammatesState, get('loading'));
export const getPages = createSelector(getTeammatesState, get('pages'));
export const getError = createSelector(getTeammatesState, get('error'));

export const getSignedInTeammate = createSelector(getTeammatesState, get('signedInTeammate'));
export const getSignedInTeammateFirstName = createSelector(getSignedInTeammate, get('first_name'));
export const getSignedInTeammateLastName = createSelector(getSignedInTeammate, get('last_name'));
export const getSignedInTeammateUsername = createSelector(getSignedInTeammate, get('username'));

export const getSignedInTeammateGroups = createSelector(getSignedInTeammate, get('groups'));

// This function below exists to set the permissions for the updated Ops Supervisor role. Once the
// new teammates functionality is approved, this function can be removed and the Ops Supervisor
// navigation permissions can be updated in teammatesAccess/navigationPermissionsForGroup.js
// with the permissions below.
const setOpsSupervisorPermissions = () => {
  const OpsSupervisorIndex = findIndex({ slug: 'Ops Supervisor' }, navigationPermissionsForGroup);
  const newOpsSupervisorNavPermissions = assign(navigationPermissionsForGroup[OpsSupervisorIndex], {
    navigationPermissions: [
      ORDER_LIST,
      ORDER_VIEW,

      EXPORTS,
      DATA_PORTAL,

      TEAMMATES_LIST,
      TEAMMATES_DETAIL,
      TEAMMATES_EDIT,

      PRODUCTION_LIST,
      OLD_FLOW_PRODUCTION_SCREEN,
      NEW_FLOW_PRODUCTION_SCREEN,
      PACK_SCREEN,

      BUFFER_SPACES_LIST,

      AUTO_DISPATCH,
      MANUAL_DISPATCH,
    ],
  });
  navigationPermissionsForGroup[OpsSupervisorIndex] = newOpsSupervisorNavPermissions;
  return navigationPermissionsForGroup;
};

export const getMenuAccess = createSelector(
  getSignedInTeammateGroups,
  (groups, showPermissionFilter) => {
    const navigationPermissionsForRoles = showPermissionFilter
      ? setOpsSupervisorPermissions()
      : navigationPermissionsForGroup;
    const menuItem = flatMap(({ name }) => {
      const navigationPermissions = get(
        'navigationPermissions',
        find({ slug: name }, navigationPermissionsForRoles)
      );
      return filter(item => includes(item.slug, navigationPermissions), menuItems);
    }, groups);

    return uniq(menuItem);
  }
);
