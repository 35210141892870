const initialState = {
  customers: [],
  coupons: { credits: {}, promos: {}, creditsPages: {}, promosPages: {} },
  orders: [],
  subscriptions: [],
  surveys: [],
  cart: {},
  profiles: [],
  formulasPreview: {},
  hairProfileUpdatedAt: null,
  loading: false,
  pages: {},
  error: false,
  details: {},
  imageDocs: {}, // dict of [customerPubkey]: { ...document, file, objectUrl }
  errorDetectionResults: {}, // dict of [customerPubkey]: ErrorDetectionResult
};
export default initialState;
