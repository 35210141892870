import find from 'lodash/fp/find';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';

import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);

export const giftsNameByVariantSlug = {
  gift_credit_50: '50$ Prose Gift Card',
  gift_credit_75: '75$ Prose Gift Card',
  gift_credit_100: '100$ Prose Gift Card',
  gift_credit_150: '150$ Prose Gift Card',
  complete_set: 'The Complete Set',
  essentials_set: 'The Essentials',
};

export const reasons = {
  just_because: 'Just because',
  birthday: 'Birthday',
  mother_s_day: "Mother's Day",
  mother_s_day__postcard: "Mother's Day",
  mother_s_day_2020: "Mother's Day 2020",
  father_s_day: "Father's Day",
  graduation: 'Graduation',
  holidays: 'Holidays',
};

const complementaryGifts = {
  mother_s_day__postcard: 'Postcard',
};

export const humanizeReason = reason => reasons[reason] || `Unknown reason: ${reason}`;

export const reasonWithComplementaryGift = reason => {
  let string = complementaryGifts[reason];

  if (!string) {
    string = '';
  }
  if (string !== '') {
    string += ' - ';
  }

  string += humanizeReason(reason);

  return string || `Can't find a gift for: ${reason}`;
};

export const itemListContainsLoyaltyGift = flow(
  find(['variant.product.type', 'loyalty_gift']),
  Boolean
);

export const orderContainsLoyaltyGift = flow(get('items'), itemListContainsLoyaltyGift);

const firstBoxRegex = /(_1)$/;

const subscriptionCheck = (box, prevOrderLimitedDate, includeForFirstSubscriptionOrder) => {
  const {
    customer: { pref_ship_magazine },
    order: { is_subscription, nth_subscription_order, prev_subscription_placed_at },
  } = box;
  return (
    is_subscription &&
    pref_ship_magazine &&
    firstBoxRegex.exec(box.serial_no) !== null &&
    ((includeForFirstSubscriptionOrder && !prev_subscription_placed_at) ||
      prev_subscription_placed_at < prevOrderLimitedDate ||
      (nth_subscription_order === 2 && prev_subscription_placed_at > prevOrderLimitedDate))
  );
};

export const promotionsGifts = [
  // Object Example
  // {
  //   start_date: '2019-05-01T00:00:00Z', // Date format UTC YYYY-MM-DDThh:mm:ssZ
  //   end_date: '2019-05-25T00:30:00Z', // 30 minutes later than planned in case of late order completions?
  //   products: ['shampoo', 'conditioner'], // List of products required to unlock
  //   slug: 'gwp_memorial_2019', // Actually not used but in prevision of this being an objecvt item later and directly in the DB?
  //   name: 'Add Travel Bottles', // What to be added
  // },

  // Template
  // {
  //   start_date: '2019-MM-DDThh:mm:00Z', // Date format UTC YYYY-MM-DDThh:mm:ssZ
  //   end_date: '2019-MM-DDThh:mm:00Z', // 30 minutes later than planned in case of late order completions?
  //   products: [], // List of products required to unlock - if no products restriction put `null` instead
  //   slug: '', // Actually not used but in prevision of this being an objecvt item later and directly in the DB?
  //   name: '', // What to be added
  // },

  // Promotion gifts

  // Memorial Day 2019
  // at least one custom shampoo and one custom conditioner
  // Promotion will run from 5/24/19 12:00AM EST to 5/27/19 11:59PM EST
  {
    type: 'gwp',
    name: 'Add Travel Bottles',
    slug: 'gwp_memorial_2019',
    start_date: '2019-05-24T04:00:00Z',
    end_date: '2019-06-01T05:00:00Z',
    conditions: ({ box }) =>
      ['shampoo', 'conditioner'].every(requiredItem =>
        box.items.find(
          item =>
            item.variant.is_formula &&
            item.item_object.skeleton.type === requiredItem &&
            item.quantity > 0
        )
      ),
    quantity: 2,
    quantity_in_box: 2,
    item_object: {
      pubkey: '00000000-0000-4000-a000-000000000001',
      // serial_no: 'gwp_001',
    },
  },

  // Fourth of July 2019 POUCH
  // Promotion will run from 06/28/19 12:00AM EST to 07/07/19 11:59PM EST
  // Order must contain 2 product or more, the box should not contain an oil
  {
    type: 'gwp',
    name: 'Gift: 4th of July pouch',
    slug: 'fourth_july_pouch',
    start_date: '2019-06-28T04:00:00Z',
    end_date: '2019-07-08T03:59:00Z',
    conditions: ({ box, orderItems }) =>
      orderItems &&
      orderItems.map(item => item.quantity).reduce((a, b) => a + b, 0) >= 2 &&
      box.items.find(item => item.item_object.skeleton.slug !== 'leavein_oil'),
    quantity: 1,
    quantity_in_box: 1,
    item_object: {
      pubkey: '00000000-0000-4000-a000-000000000002',
      // serial_no: 'gwp_002',
    },
  },

  // Labor Day 2019
  // Promotion will run from 08/26/19 12:00AM EST to 09/02/19 11:59PM EST
  // Order must contain 2 product or more, the box should not contain an oil
  {
    type: 'gwp',
    name: 'Add Travel Bottles',
    slug: 'gwp_labor_2019',
    start_date: '2019-08-26T04:00:00Z',
    end_date: '2019-09-03T04:15:00Z',
    conditions: ({ box, orderItems }) =>
      orderItems &&
      orderItems.map(item => item.quantity).reduce((a, b) => a + b, 0) >= 2 &&
      box.items.find(item => item.item_object.skeleton.slug !== 'leavein_oil'),
    quantity: 2,
    item_object: {
      pubkey: '00000000-0000-4000-a000-000000000003',
      // serial_no: 'gwp_003',
    },
  },

  // End of Year 2019
  // at least $50
  // Promotion will run from 12/23/19 12:00AM EST to 01/02/20 11:59PM EST
  {
    type: 'gwp',
    name: 'Add Clips Duo',
    slug: 'gwp_eoy_2019',
    start_date: '2019-12-23T05:00:00Z',
    end_date: '2020-01-03T05:00:00Z',
    conditions: ({ box }) => {
      return box.order.total_price_with_taxes >= 50 && firstBoxRegex.exec(box.serial_no) !== null;
    },
    quantity: 1,
    quantity_in_box: 1,
    item_object: {
      pubkey: '00000000-0000-4000-a000-000000000004',
      // serial_no: 'gwp_004',
    },
  },

  // Valentine's Day 2020
  // at least $50
  // Promotion will run from 02/06/2020 12:00AM EST to 16/02/2020 11:59PM EST
  {
    type: 'gwp',
    name: 'Add Satin Pillowcase',
    slug: 'gwp_vday_2020',
    start_date: '2020-02-06T05:00:00Z',
    end_date: '2020-02-17T05:00:00Z',
    conditions: ({ box }) => {
      return box.order.total_price_with_taxes >= 50 && firstBoxRegex.exec(box.serial_no) !== null;
    },
    quantity: 1,
    item_object: {
      pubkey: '00000000-0000-4000-a000-000000000005',
      // serial_no: 'gwp_005',
    },
  },

  // GWP 4th of July 2020
  // at least 3 products
  // Promotion will run from 06/26/2020 12:00AM EST to 07/05/2020 11:59PM EST
  {
    type: 'gwp',
    name: 'Add French Netted tote',
    slug: 'gwp_4th_july_2020',
    start_date: '2020-06-26T04:00:00Z',
    end_date: '2020-07-06T04:00:00Z',
    conditions: ({ box, orderItems }) =>
      orderItems &&
      orderItems
        .filter(item => item.variant.is_formula)
        .map(item => item.quantity)
        .reduce((a, b) => a + b, 0) >= 3 &&
      firstBoxRegex.exec(box.serial_no) !== null,
    quantity: 1,
    quantity_in_box: 1,
    item_object: {
      pubkey: '00000000-0000-4000-a000-000000000006',
      // serial_no: 'gwp_006',
    },
  },

  // GWP Labor Day 2020
  // new subcription
  // Promotion will run from 08/28/2020 12:00AM EST to 07/09/2020 11:59PM EST
  {
    type: 'gwp',
    name: 'GWP Labor Day',
    slug: 'gwp_labor_day_2020',
    start_date: '2020-08-28T04:00:00Z',
    end_date: '2020-09-10T04:00:00Z',
    conditions: ({
      box: {
        serial_no,
        order: { is_subscription, subscription_created_at },
      },
    }) => {
      return (
        is_subscription &&
        subscription_created_at >= '2020-08-28-T04:00:00Z' &&
        subscription_created_at <= '2020-09-08-T04:00:00Z' &&
        firstBoxRegex.exec(serial_no) !== null
      );
    },
    quantity: 1,
    quantity_in_box: 1,
    item_object: {
      pubkey: '00000000-0000-4000-a000-000000000007',
      // serial_no: 'gwp_006',
    },
  },

  // GWP 3 Year Anniversary 2020
  // new subcription
  // Promotion will run from 10/23/2020 12:00AM EST to 11/02/2020 11:59PM EST
  {
    type: 'gwp',
    name: 'GWP Anniversary Headband',
    slug: 'gwp_3_year_anniversary_2020',
    start_date: '2020-10-23T04:00:00Z',
    end_date: '2020-11-05T05:00:00Z', // +3 days and +1 hour (winter time change)
    quantity: 1,
    quantity_in_box: 1,
    item_object: {
      pubkey: '00000000-0000-4000-a000-000000000008',
      // serial_no: 'gwp_006',
    },
    conditions: ({
      box: {
        serial_no,
        order: { is_subscription, prev_subscription_placed_at, subscription_created_at },
      },
    }) => {
      return (
        is_subscription &&
        subscription_created_at >= '2020-10-23T04:00:00Z' &&
        subscription_created_at <= '2020-11-03T05:00:00Z' &&
        firstBoxRegex.exec(serial_no) !== null &&
        (prev_subscription_placed_at === null ||
          prev_subscription_placed_at < '2020-10-23T04:00:00Z')
      );
    },
  },

  // GWP Holiday 2020 Eye Mask
  // New subcription
  // Promotion will run from 12/23/2020 12:00AM EST to 01/03/2021 11:59PM EST
  // +1 hour (winter time change)
  {
    type: 'gwp',
    name: 'GWP Holiday 2020 Eye Mask',
    slug: 'gwp_holiday_2020_eye_mask',
    start_date: '2020-12-23T05:00:00Z',
    end_date: '2021-01-06T05:00:00Z', // +3 days
    quantity: 1,
    quantity_in_box: 1,
    item_object: {
      pubkey: '00000000-0000-4000-a000-000000000009',
    },
    conditions: ({
      box: {
        serial_no,
        order: { is_subscription, prev_subscription_placed_at, subscription_created_at },
        items,
      },
    }) => {
      return (
        is_subscription &&
        subscription_created_at >= '2020-12-23T05:00:00Z' &&
        subscription_created_at <= '2021-01-04T05:00:00Z' &&
        firstBoxRegex.exec(serial_no) !== null &&
        (prev_subscription_placed_at === null ||
          prev_subscription_placed_at < '2020-12-23T05:00:00Z') &&
        items.find(
          item => !['leavein_oil', 'shimmer_hairoil'].includes(item.item_object.skeleton?.slug)
        )
      );
    },
  },

  // GWP Holiday 2020 - Eye Mask Postcard
  // New subcription
  // Promotion will run from 12/23/2020 12:00AM EST to 01/03/2021 11:59PM EST
  // +1 hour (winter time change)
  {
    type: 'postcard',
    name: 'GWP Holiday 2020 Postcard',
    slug: 'gwp_holiday_2020_postcard',
    start_date: '2020-12-23T05:00:00Z',
    end_date: '2021-01-06T05:00:00Z', // +3 days
    quantity: 1,
    quantity_in_box: 1,
    item_object: {
      pubkey: '6fbaab34-fc7e-4117-bd7a-1931444b6f58',
    },
    conditions: ({
      box: {
        serial_no,
        order: { is_subscription, prev_subscription_placed_at, subscription_created_at },
        items,
      },
    }) => {
      return (
        is_subscription &&
        subscription_created_at >= '2020-12-23T05:00:00Z' &&
        subscription_created_at <= '2021-01-04T05:00:00Z' &&
        firstBoxRegex.exec(serial_no) !== null &&
        (prev_subscription_placed_at === null ||
          prev_subscription_placed_at < '2020-12-23T05:00:00Z') &&
        items.find(
          item => !['leavein_oil', 'shimmer_hairoil'].includes(item.item_object.skeleton?.slug)
        )
      );
    },
  },

  // Labor Day 6th of September 2021 - Hair Towel
  // Subscription to any 2 products (this does NOT include brushes)
  {
    type: 'gwp',
    name: 'Hair Towel GWP',
    slug: 'hair_towel_gwp',
    start_date: '2021-09-03T04:00:00Z',
    end_date: '2021-09-07T03:59:00Z',
    quantity: 1,
    quantity_in_box: 1,
    item_object: {
      pubkey: '682bf930-fb96-4b24-8d03-2d07814ba4fc',
    },
    conditions: () => false,
  },

  // GWP 4th of July 2021 French Market Bag
  // Promotion will run from TBD to TBD
  {
    type: 'gwp',
    name: 'Filet Bag GWP',
    slug: 'filet_bag_gwp',
    // start_date: 'TBD',
    // end_date: 'TBD',
    quantity: 1,
    quantity_in_box: 1,
    item_object: {
      pubkey: 'f553d7d6-449d-45ea-81c9-c277573c8b67',
    },
    isDateAgnostic: true, // It should be API driven, so it will not be driven by date
    conditions: () => false, // Also put in the box by the API itself so no need for condition(s)
  },

  // Subscription Magazines
  {
    type: 'magazine',
    start_date: '2019-08-01T00:00:00Z',
    end_date: '2019-12-15T23:59:59Z',
    slug: 'magazine_001',
    issue_number: 1,
    issue_title: 'Inclusivity',
    name: 'Magazine №1 - Inclusivity',
    quantity: 1,
    item_object: {
      pubkey: '00000000-0000-4000-a001-000000000001',
      // serial_no: 'magazine_001',
    },
    conditions: ({ box }) => subscriptionCheck(box, '2019-08-01T00:00:00Z', true),
  },
  {
    type: 'magazine',
    start_date: '2019-12-16T00:00:00Z',
    end_date: '2020-03-12T23:59:59Z',
    slug: 'magazine_002',
    issue_number: 2,
    issue_title: 'Power',
    name: 'Magazine №2 - Power',
    quantity: 1,
    quantity_in_box: 1,
    item_object: {
      pubkey: '00000000-0000-4000-a001-000000000002',
      // serial_no: 'magazine_002',
    },
    conditions: ({ box }) => subscriptionCheck(box, '2019-12-16T00:00:00Z', true),
  },
  {
    type: 'magazine',
    start_date: '2020-09-24T00:00:00Z',
    end_date: '2020-11-04T07:00:00Z',
    slug: 'magazine_003',
    issue_number: 3,
    issue_title: 'Fantasy',
    name: 'Magazine №3 - Fantasy',
    quantity: 1,
    quantity_in_box: 1,
    item_object: {
      pubkey: '00000000-0000-4000-a001-000000000003',
      // serial_no: 'magazine_003',
    },
    conditions: ({ box }) =>
      subscriptionCheck(box, '2020-09-24T00:00:00Z', false) &&
      box.items.find(item => item.item_object?.skeleton.slug !== 'leavein_oil') &&
      box.size > 1,
  },
  {
    type: 'magazine',
    start_date: '2021-02-09T00:00:00Z',
    end_date: '2050-12-25T23:59:59Z',
    slug: 'magazine_004',
    issue_number: 4,
    issue_title: 'Intention',
    name: 'Magazine №4 - Intention',
    quantity: 1,
    quantity_in_box: 1,
    item_object: {
      pubkey: '00000000-0000-4000-a001-000000000004',
      // serial_no: 'magazine_004',
    },
    conditions: ({ box }) =>
      subscriptionCheck(box, '2021-02-09T00:00:00Z', false) &&
      box.items.find(item => item.item_object?.skeleton.slug !== 'leavein_oil') &&
      box.size > 1,
  },

  // Magazines Postcards
  {
    type: 'postcard',
    start_date: '2019-08-01T00:00:00Z',
    end_date: '2019-12-15T23:59:59Z',
    slug: 'postcard_001',
    issue_number: 1,
    issue_title: 'Inclusivity',
    name: 'Postcard for №1 - Inclusivity',
    quantity: 1,
    item_object: {
      pubkey: '00000000-0000-4000-a002-000000000001',
      // serial_no: 'postcard_001',
    },
    conditions: ({ box }) => subscriptionCheck(box, '2019-08-01T00:00:00Z', true),
  },

  // Scalp Mask Postcard
  // Box contains a scalp_mask
  // Postcard to be added between March 10th and June 10th
  {
    type: 'postcard',
    start_date: '2010-03-10T05:00:00Z',
    end_date: '2020-06-10T05:00:00Z',
    slug: 'launch_scalp_mask',
    name: 'Postcard Scalp Mask',
    quantity: 1,
    quantity_in_box: 1,
    item_object: {
      pubkey: '00000000-0000-4000-a002-000000000003',
      // serial_no: 'postcard_003',
    },
    conditions: ({ box }) =>
      box.items
        .filter(item => item.variant.is_formula)
        .find(item => item.item_object.skeleton.slug === 'scalp_mask_normal'),
  },

  // Prelude Postcard
  // Box contains a formula with Prelude fragrance
  // Postcard to be added for as long as the fragrance is available (for now at least)
  {
    type: 'postcard',
    start_date: '2020-03-20T05:00:00Z',
    end_date: '2020-06-20T05:00:00Z',
    slug: 'prelude',
    name: 'Prelude 🌻 Postcard',
    quantity: 1,
    quantity_in_box: 1,
    item_object: {
      pubkey: '00000000-0000-4000-a002-000000000004',
      // serial_no: 'postcard_004',
    },
    conditions: ({ box }) =>
      box.items
        .filter(item => item.variant.is_formula)
        .find(item =>
          item.item_object.ingredients
            .filter(ingredient => ingredient.type === 'fragrance')
            .some(ingredient => ingredient.name === 'prelude')
        ),
  },

  // Positivity Postcard
  // Postcard to be added for as long as the feature flag is enabled
  {
    type: 'postcard',
    name: 'Positivity Postcard',
    slug: 'positivity-postcard', // Used for react component key when rendered from array
    quantity: 1,
    quantity_in_box: 1,
    item_object: {
      pubkey: '00000000-0000-4000-a002-000000000005',
    },
    isDateAgnostic: true,
    conditions: () => false,
  },

  // Oasis Postcard
  // Box contains a formula with Oasis fragrance
  // Postcard to be added for as long as the fragrance is available (for now at least)
  {
    type: 'postcard',
    start_date: '2020-06-11T05:00:00Z',
    end_date: '2020-09-24T05:00:00Z',
    slug: 'oasis',
    name: 'Oasis 🌴 Postcard',
    quantity: 1,
    quantity_in_box: 1,
    item_object: {
      pubkey: '00000000-0000-4000-a002-000000000006',
      // serial_no: 'postcard_006',
    },
    conditions: ({ box }) =>
      box.items
        .filter(item => item.variant.is_formula)
        .find(item =>
          item.item_object.ingredients
            .filter(ingredient => ingredient.type === 'fragrance')
            .some(ingredient => ingredient.name === 'oasis')
        ),
  },
  // Arcadia Postcard
  // Box contains a formula with Oasis fragrance
  // Postcard to be added for as long as the fragrance is available (for now at least)
  {
    type: 'postcard',
    isDateAgnostic: true,
    slug: 'arcadia',
    name: 'Arcadia 🌳 Postcard',
    quantity: 1,
    quantity_in_box: 1,
    item_object: {
      pubkey: '00000000-0000-4000-a002-000000000007',
      // serial_no: 'postcard_007',
    },
    conditions: ({ box }) =>
      box.items
        .filter(item => item.variant.is_formula)
        .find(item =>
          item.item_object.ingredients
            .filter(ingredient => ingredient.type === 'fragrance')
            .some(ingredient => ingredient.name === 'arcadia')
        ),
  },
  // Candela Postcard
  // Box contains a formula with Candela fragrance
  // Postcard to be added for as long as the fragrance is available (for now at least)
  {
    type: 'postcard',
    isDateAgnostic: true,
    slug: 'candela',
    name: 'Candela 🕯 Postcard',
    quantity: 1,
    quantity_in_box: 1,
    item_object: {
      pubkey: '00000000-0000-4000-a002-000000000008',
      // serial_no: 'postcard_008',
    },
    conditions: ({ box }) =>
      box.items
        .filter(item => item.variant.is_formula)
        .find(item =>
          item.item_object.ingredients
            .filter(ingredient => ingredient.type === 'fragrance')
            .some(ingredient => ingredient.name === 'candela')
        ) && firstBoxRegex.exec(box.serial_no) !== null,
  },
  // Vitae Postcard
  {
    type: 'postcard',
    isDateAgnostic: true,
    slug: 'vitae',
    name: 'Vitae 🍊 Postcard',
    quantity: 1,
    quantity_in_box: 1,
    item_object: {
      pubkey: '27ab6b88-5114-4c00-ba32-15c1ecf6a799',
    },
    conditions: () => false,
  },

  // Corsica Hair Perfume 10ML Postcard'
  // Conditions TBD
  {
    type: 'postcard',
    name: '3rd Subscription Order Loyalty Gift - Perfume Corsica Postcard',
    slug: 'corsica_hair_perfume_10_ml_postcard',
    // start_date: 'TBD',
    // end_date: 'TBD',
    quantity: 1,
    quantity_in_box: 1,
    item_object: {
      pubkey: 'c26ba14f-218b-404f-a796-0b57f3f0c7d4',
    },
    isDateAgnostic: true, // It should be API driven, so it will not be driven by date
    conditions: () => false, // Also put in the box by the API itself so no need for condition(s)
  },

  // Membership Postcard Version 1 (hair type 1 & 2)
  // Order is the first subscription order since membership launch
  // Customer has hair type 1 or 2
  {
    type: 'postcard', // Not sure if should be postcard
    isDateAgnostic: true,
    slug: 'membership_postcard_version_1',
    name: 'Membership Postcard Version 1',
    quantity: 1,
    quantity_in_box: 1,
    item_object: {
      pubkey: '717a9229-ed61-4690-bef2-e9d4dc60b506',
      // serial_no: 'postcard_007',
    },
    conditions: () => false,
  },
  // Membership Postcard Version 2 (hair type 3 and +)
  // Order is the first subscription order since membership launch
  // Customer has hair type 3 or +
  {
    type: 'postcard', // Not sure if should be postcard
    isDateAgnostic: true,
    slug: 'membership_postcard_version_2',
    name: 'Membership Postcard Version 2',
    quantity: 1,
    item_object: {
      pubkey: 'd823e60e-dc05-4e11-9628-1b288ea0ed56',
      // serial_no: 'postcard_008',
    },
    conditions: () => false,
  },
  // Loyalty Gift
  // 3rd Subscription order Gift
  // Use existing stock
  {
    type: 'loyalty_gift',
    isDateAgnostic: true,
    slug: 'loyalty_gift_3',
    name: '3rd Subscription Order Loyalty Gift',
    quantity: 1,
    quantity_in_box: 1,
    item_object: { pubkey: '4df14246-d2f6-48e8-aa52-8f279bebbe00' },
    conditions: () => false,
  },
  // Loyalty Gift
  // 3rd Order Loyalty Gift - Comb 1-2
  // Use existing stock
  {
    type: 'loyalty_gift',
    isDateAgnostic: true,
    slug: 'loyalty_gift_3_comb_type_1_2',
    name: '3rd Order Loyalty Gift - Comb 1-2',
    quantity: 1,
    quantity_in_box: 1,
    item_object: { pubkey: '57344364-e017-405e-a76d-843705e7ab63' },
    conditions: () => false,
  },
  // Loyalty Gift
  // 3rd Order Loyalty Gift - Comb 3-4
  // Use existing stock
  {
    type: 'loyalty_gift',
    isDateAgnostic: true,
    slug: 'loyalty_gift_3_comb_type_3_4',
    name: '3rd Order Loyalty Gift - Comb 3-4',
    quantity: 1,
    quantity_in_box: 1,
    item_object: { pubkey: '92125550-1401-4abf-82e0-78aedc0d93b3' },
    conditions: () => false,
  },
  // Memorial Day 2021
  // Pillowcase Previously 6th Subscription order Gift
  {
    type: 'gwp',
    isDateAgnostic: true,
    slug: 'gwp_2021_memorial_day',
    name: 'Memorial day GWP - ex-6th order LG',
    quantity: 1,
    quantity_in_box: 1,
    item_object: { pubkey: '6ebf5712-b552-45d4-860d-015cc02dec29' },
    conditions: () => false,
  },
  // Loyalty Gift
  // 3rd Order Loyalty Gift - Corsica Hair Perfume
  // Use existing stock
  {
    type: 'loyalty_gift',
    isDateAgnostic: true,
    slug: 'loyalty_gift_3_corsica_hair_perfume',
    name: '3rd Subscription Order Loyalty Gift - Perfume Corsica',
    quantity: 1,
    quantity_in_box: 1,
    item_object: { pubkey: '5d255ff7-d24f-48f2-9965-09a4c3578700' },
    conditions: () => false,
  },
];

export const complementaryItemsUtils = args => {
  if (isEmpty(args?.box)) {
    return [];
  }
  const placedAtMoment = dayjs.utc(args?.box?.order?.placed_at);
  return promotionsGifts.filter(promotionGift => {
    if (promotionGift.isDateAgnostic) {
      // Ignore Start and End Date (they might be set just for CX visibility purpose)
      return promotionGift.conditions(args);
    }
    return (
      placedAtMoment.isSameOrAfter(promotionGift.start_date, 'minute') &&
      placedAtMoment.isSameOrBefore(promotionGift.end_date, 'minute') &&
      promotionGift.conditions(args)
    );
  });
};
