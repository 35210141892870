import compact from 'lodash/fp/compact';
import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import findIndex from 'lodash/fp/findIndex';
import flatten from 'lodash/fp/flatten';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import head from 'lodash/fp/head';
import isEmpty from 'lodash/fp/isEmpty';
import last from 'lodash/fp/last';
import map from 'lodash/fp/map';
import _ from 'lodash/fp/placeholder';
import some from 'lodash/fp/some';
import subtract from 'lodash/fp/subtract';
import tail from 'lodash/fp/tail';
import uniq from 'lodash/fp/uniq';
import update from 'lodash/fp/update';

import { createSelector } from 'reselect';

import { getItemName, getItemType } from '../../utils/itemsHelper';

import { capitalize, humanize } from '../../helpers';
import { getBoxesByOrderPubkey } from '../boxes/selectors';
import { getNonRefundableItemsFromCoupons, getOrderItems } from './selectors';

const getOrdersState = state => state.orders;

export const getOrders = createSelector(getOrdersState, get('orders'));

export const getOrderByPubkey = createSelector(
  getOrders,
  (state, props) => props.orderPubkey,
  (orders, pubkey) => find({ pubkey }, orders)
);

export const getTotalPrice = createSelector(getOrderByPubkey, get('total_price'));

// -- Refund getters
// orders.orders[orderPubkey].refunds
export const getRefunds = createSelector(getOrderByPubkey, get('refunds'));

export const hasSomeShippingRefund = createSelector(
  getRefunds,
  some(refund => refund.refund_total_shipping !== 0)
);
export const hasRefund = createSelector(getRefunds, refunds => !isEmpty(refunds));

export const getFirstRefundType = createSelector(getRefunds, flow(head, get('type')));

// orders.orders[orderPubkey].refunds[refundPubkey]
export const getRefundByPubkey = createSelector(
  getRefunds,
  (state, props) => props.refundPubkey,
  (refunds, pubkey) => find({ pubkey }, refunds)
);
// orders.orders[orderPubkey].refunds[refundPubkey].refund_total_shipping
export const getShippingRefund = createSelector(getRefundByPubkey, get('refund_total_shipping'));
// orders.orders[orderPubkey].refunds[refundPubkey].type
export const getRefundType = createSelector(getRefundByPubkey, get('type')); //  "PERCENTAGE"|"FLAT"|"ITEMS"
// orders.orders[orderPubkey].refunds[refundPubkey].refund_percentage
export const getRefundPercentage = createSelector(getRefundByPubkey, get('refund_percentage')); // For type "PERCENTAGE" Number
// orders.orders[orderPubkey].refunds[refundPubkey].refund_amount_without_taxes
export const getRefundAmountWithoutTaxes = createSelector(
  getRefundByPubkey,
  get('refund_amount_without_taxes')
); // For Type "FLAT" Number
// orders.orders[orderPubkey].refunds[refundPubkey].refund_items
const getRefundItemsPubkeys = createSelector(getRefundByPubkey, get('refund_items_details')); // For Type "ITEMS" Array
export const getRefundItems = createSelector(
  [state => state, getRefundItemsPubkeys, getOrderItems],
  (state, refundItemsPubkeys, orderItems) => {
    const refundItems = map(
      refundItemPubkey => find({ pubkey: refundItemPubkey?.order_item }, orderItems),
      refundItemsPubkeys
    );

    return compact(flatten(refundItems));
  }
); // For Type "ITEMS" Array of refundable items

// orders.orders[orderPubkey].refunds[refundPubkey].refund_text
export const getRefundText = createSelector(getRefundByPubkey, get('refund_text')); //  Text,
// orders.orders[orderPubkey].refunds[refundPubkey].refund_amount
export const getRefundAmount = createSelector(getRefundByPubkey, get('refund_amount')); //  Text, // should be same value as refund_amount_without_taxes (if exists)
// orders.orders[orderPubkey].refunds[refundPubkey].refund_amount_with_taxes
export const getRefundAmountWithTaxes = createSelector(
  getRefundByPubkey,
  get('refund_amount_with_taxes')
); //  Text,
// orders.orders[orderPubkey].refunds[refundPubkey].order_total_price
export const getRefundOrderTotalPrice = createSelector(getRefundByPubkey, get('order_total_price')); //  Text,
// orders.orders[orderPubkey].refunds[refundPubkey].created_at
export const getRefundCreatedAt = createSelector(getRefundByPubkey, get('created_at')); //  Date,
// orders.orders[orderPubkey].refunds[refundPubkey].refund_tag
export const getRefundTag = createSelector(getRefundByPubkey, get('refund_tag')); //  "pre_processing"|"post_processing"|"unknown",

export const getRefundTags = createSelector(
  [getRefundByPubkey, getRefundTag],
  (refund, refundTag) => {
    if (refundTag !== 'unknown') {
      return [refundTag];
    }
    return flow(get('refund_items_details'), map('refund_tag'), uniq)(refund);
  }
); //  array of "pre_processing"|"post_processing"|"unknown",

// orders.orders[orderPubkey].refunds[refundPubkey].by
export const getRefundAuthor = createSelector(getRefundByPubkey, get('by'));
// orders.orders[orderPubkey].refunds[refundPubley].by.first_name
export const getRefundFirstName = createSelector(getRefundAuthor, get('first_name')); //  String,
// orders.orders[orderPubkey].refunds[refundPubley].by.last_name
export const getRefundLastName = createSelector(getRefundAuthor, get('last_name')); //  String,

// -- Refund Computed Derived states : Tag Types

const tagTypeToPrettyPrint = {
  'pre_processing-FLAT_TYPE': 'Flat refund',
  'post_processing-FLAT_TYPE': 'Flat refund',
  'unknown-FLAT_TYPE': 'Flat refund',
  'pre_processing-ITEMS': 'Items Canceled',
  'post_processing-ITEMS': 'Items Refunded, Post-processing',
  'pre_processing-SHIPPING': 'Shipping refund, Pre-processing',
  'post_processing-SHIPPING': 'Shipping refund, Post-processing',
};

export const getRefundTagTypes = createSelector(getRefundTags, getRefundType, (refundTags, type) =>
  map(
    refundTag => `${refundTag}-${type === 'PERCENTAGE' || type === 'FLAT' ? 'FLAT_TYPE' : type}`,
    refundTags
  )
);

export const getRefundTagTypesPrettyPrint = createSelector(
  getRefundTagTypes,
  map(get(_, tagTypeToPrettyPrint))
);

const getAllRefundTagTypes = createSelector(
  state => state,
  (state, props) => props.orderPubkey,
  getRefunds,
  (state, orderPubkey, refunds) =>
    map(
      ({ pubkey: refundPubkey }) => getRefundTagTypes(state, { orderPubkey, refundPubkey }),
      refunds
    )
);
export const getAllPossibleRefundTagTypes = createSelector(
  getAllRefundTagTypes,
  flow(flatten, uniq)
);

export const getAllPossibleRefundTagTypesPrettyPrint = createSelector(
  getAllPossibleRefundTagTypes,
  flow(map(get(_, tagTypeToPrettyPrint)), uniq)
);

export const getTotalAfterAllRefunds = createSelector(
  getRefunds,
  flow(last, get('order_total_price_with_taxes'))
);

export const getLastRefundTotalPrice = createSelector(
  getRefunds,
  flow(last, get('order_total_price'))
);

export const getLastTotalPrice = createSelector(
  [getLastRefundTotalPrice, getTotalPrice],
  (lastRefundTotalPrice, orderTotalPrice) => lastRefundTotalPrice ?? orderTotalPrice
);

export const isPercentageDiscount = createSelector(getRefundType, type => type === 'PERCENTAGE'); //  "PERCENTAGE"|"FLAT"|"ITEMS"
export const isFlatDiscount = createSelector(getRefundType, type => type === 'FLAT'); //  "PERCENTAGE"|"FLAT"|"ITEMS"
export const isProductRefund = createSelector(getRefundType, type => type === 'ITEMS'); //  "PERCENTAGE"|"FLAT"|"ITEMS"

const getRefundedItems = createSelector(getRefunds, flow(map('refund_items_details'), flatten));

const getCanceledOrderItemsPubkeys = createSelector(
  getRefundedItems,
  flow(filter({ status: 'canceled', is_orphan: true }), map('order_item'))
);

export const getCanceledItems = createSelector(
  [getCanceledOrderItemsPubkeys, getOrderItems],
  (canceledOrderItemsPubkeys, items) =>
    map(
      canceledOrderItemsPubkey => find({ pubkey: canceledOrderItemsPubkey }, items),
      canceledOrderItemsPubkeys
    )
); // For Type "ITEMS" Array of canceled items

export const getCanceledItemsQty = createSelector(
  [getBoxesByOrderPubkey, getCanceledItems, (state, props) => props.itemPubkey],
  (boxes, canceledItems, itemPubkey) => {
    if (!itemPubkey) return null;

    const boxesItems = map('items', boxes);
    const canceledItemsInBox = find({ pubkey: itemPubkey }, flatten(boxesItems));

    if (canceledItemsInBox) {
      return subtract(
        get('quantity', canceledItemsInBox),
        get('quantity_in_box', canceledItemsInBox)
      );
    }

    return get('quantity', find({ pubkey: itemPubkey }, canceledItems));
  }
);

const removeFreeItemsFromBoxes =
  ([itemToRemove, ...restOfItemsToRemove] = []) =>
  (boxes = []) => {
    // Recursion Stop condition
    if (!itemToRemove) {
      return boxes;
    }
    // Find two level indexes (box & item level)
    let itemIndex = -1;
    const boxIndex = findIndex(box => {
      itemIndex = findIndex({ variant: { product: { type: itemToRemove } } })(box?.items);
      return itemIndex !== -1;
    })(boxes);
    // Remove item & update quantity, this should be null & -1 safe
    const updatedBoxes = flow(
      update(`[${boxIndex}].items[${itemIndex}].quantity`, quantity => quantity - 1),
      update(`[${boxIndex}].items[${itemIndex}].production_items`, tail)
    )(boxes);
    // Call back this function with the following items to remove and updated box
    return removeFreeItemsFromBoxes(restOfItemsToRemove)(updatedBoxes); // Tail Call Recursion
  };

const getNotFreeItemsInBox = createSelector(
  (_state, props) => props.boxPubkey,
  getBoxesByOrderPubkey,
  getNonRefundableItemsFromCoupons,
  (boxPubkey, boxes, nonRefundableItemList) =>
    flow(
      removeFreeItemsFromBoxes(nonRefundableItemList),
      find({ pubkey: boxPubkey }),
      get('items')
    )(boxes)
);

const getRefundableListItemsInBox = createSelector(
  getNotFreeItemsInBox,
  filter(itemInBox => get('unit_price', itemInBox) > 0)
);

export const getBoxesNotRefunded = createSelector(
  [
    state => state,
    (_state, { orderPubkey } = {}) => orderPubkey,
    getBoxesByOrderPubkey,
    getRefundedItems,
  ],
  (state, orderPubkey, boxes, refundedItems) =>
    filter(box => {
      const refundableItems = getRefundableListItemsInBox(state, {
        orderPubkey,
        boxPubkey: get('pubkey', box),
      });
      const isNotRefundedItems = map(
        refundableItem =>
          map(
            prodItems => !find({ pubkey: get('pubkey', prodItems) }, refundedItems),
            get('production_items', refundableItem)
          ),
        refundableItems
      );

      return !isEmpty(compact(flatten(isNotRefundedItems)));
    }, boxes)
);

const getNotRefundedItemsInBox = createSelector(
  getRefundableListItemsInBox,
  getRefundedItems,
  (itemsInBox, refundedItems) =>
    map(
      itemInBox =>
        update(
          'production_items',
          flow(
            map(prodItem => (find({ pubkey: prodItem.pubkey }, refundedItems) ? null : prodItem)),
            compact // To remove null entries
          ),
          itemInBox
        ),
      itemsInBox
    )
);

// boxes.list[boxPubkey].items.production_items
const getRefundableListProdItemsInItems = createSelector(
  getNotRefundedItemsInBox,
  map('production_items')
);

export const getProdItemsPubkey = createSelector(
  getRefundableListProdItemsInItems,
  flow(flatten, map('pubkey'))
); // prodItemsPubkey

// boxes.list[boxPubkey].items[prodItemPubkey]
const getItemInBoxByProdItemPubkey = createSelector(
  getNotRefundedItemsInBox,
  (state, props) => props.itemPubkey,
  (itemsInBox, pubkey) => find({ production_items: [{ pubkey }] }, itemsInBox)
);

// boxes.list[boxPubkey].items[prodItemPubkey].itemPubkey
const itemPubkeyInBox = createSelector(getItemInBoxByProdItemPubkey, get('pubkey'));

// order.items[itemPubkey]
const getItemInOrderByItemPubkeyInBox = createSelector(
  [itemPubkeyInBox, getOrderItems],
  (itemObjectPubkey, itemsInOrder) => find({ pubkey: itemObjectPubkey }, itemsInOrder)
);

// order.items[itemObjectPubkey].unit_price_with_discount
export const getItemUnitPriceWithDiscount = createSelector(
  getItemInOrderByItemPubkeyInBox,
  get('unit_price_with_discount')
);

// boxes.list[boxPubkey].items[prodItemPubkey].variant
export const getItemVariant = createSelector(getItemInBoxByProdItemPubkey, get('variant'));
// boxes.list[boxPubkey].items[prodItemPubkey].variant.slug
export const getItemSlug = createSelector(getItemVariant, get('slug'));

export const getFormattedCanceledItemsDetails = createSelector(getCanceledItems, items =>
  map(
    item => ({
      pubkey: item?.pubkey,
      type: {
        itemType: humanize(getItemType(item)),
        itemObject: {
          serial_no: item?.item_object?.serial_no,
        },
      },
      kind:
        getItemName(item) === '150 ml'
          ? humanize(getItemName(item))
          : capitalize(humanize(getItemName(item))),
      status: {
        hasProdItems: true,
        prodItemsStatus: [['canceled']],
      },
      quantity: 1,
      price: {
        currency: null,
        amount: item?.unit_price,
      },
      subscription: {
        isFormula: item?.variant?.is_formula,
        isSubscription: item?.is_subscription,
      },
      total: {
        currency: null,
        isSubscription: item?.is_subscription,
        qty: item?.quantity,
        unitPrice: item?.unit_price,
      },
      modal: {
        customization: item?.item_object?.customization,
        isFormula: item?.variant?.is_formula,
        itemObjectPubkey: item?.item_object?.pubkey,
      },
      isProducible: item?.variant?.is_producible,
    }),
    items
  )
);
