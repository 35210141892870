import assignWith from 'lodash/fp/assignWith';
import flow from 'lodash/fp/flow';
import isNull from 'lodash/fp/isNull';
import set from 'lodash/fp/set';
import update from 'lodash/fp/update';

import createReducer from 'utils/reduxUtils';

import * as flagFetchStatus from './constants/flagFetchStatus';
import {
  FEATURE_FLAGS_FETCH_FAIL,
  FEATURE_FLAGS_FETCH_START,
  FEATURE_FLAGS_FETCH_SUCCESS,
} from './actionTypes';
import initialState from './initialState';

const reducerToActionTypeMap = {
  [FEATURE_FLAGS_FETCH_FAIL]: set('flagFetchStatus', flagFetchStatus.USER_CONTEXT_SPECIFIC_FAIL),
  [FEATURE_FLAGS_FETCH_START]: set('flagFetchStatus', flagFetchStatus.LOADING),
  [FEATURE_FLAGS_FETCH_SUCCESS]: (state, { fetchedFeatureFlags }) =>
    flow(
      set('flagFetchStatus', flagFetchStatus.USER_CONTEXT_SPECIFIC_SUCCESS),
      update('flags', stateFlags =>
        assignWith(
          // assignWith signature : customizer, object (destination object = the state), source (source of change = fetched values)
          (stateValue, fetchedValue) => (isNull(fetchedValue) ? stateValue : fetchedValue), // Assign flags to fetchedValue only if not null
          stateFlags,
          fetchedFeatureFlags
        )
      )
    )(state),
};

export default createReducer(reducerToActionTypeMap, initialState);
