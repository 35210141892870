export const CUSTOMER_LIST = 'customer_list'; // /customers
export const CUSTOMER_VIEW = 'customer_view'; // /customers/*

export const COUPONS_VIEW = 'coupons_view'; // /coupons/*

export const ORDER_LIST = 'order_list'; // /orders
export const ORDER_VIEW = 'order_view'; // /order/*

export const SUBSCRIPTION_LIST = 'subscription_list'; // /subscriptions
export const SUBSCRIPTION_VIEW = 'subscription_view'; // /subscriptions/:pubkey/*

export const FEEDBACK_LIST = 'feedback_list'; // /feedbacks

export const GIFT_LIST = 'gift_list'; // /gifts/
export const GIFT_DETAIL = 'gift_detail'; // /gifts/:pubkey

export const PRODUCTION_LIST = 'production_list'; // /production

export const OLD_FLOW_PRODUCTION_SCREEN = 'old_flow_production'; // /production/*
export const NEW_FLOW_PRODUCTION_SCREEN = 'new_flow_production_screen'; // /production/scan-to-produce

export const PACK_SCREEN = 'pack_screen'; // /production/packing-station && /production/prepack_lab

export const AUTO_DISPATCH = 'auto_dispatch'; // /production/dispatch/auto
export const MANUAL_DISPATCH = 'manual_dispatch'; // /production/dispatch/manual
export const BUFFER_SPACES_LIST = 'buffer_spaces_list'; // /production/buffer-spaces
export const PRINT_COLLATERALS = 'print_collaterals'; // /production/print_collaterals

export const MICRO_HOLD = 'micro_hold'; // /production/quality-hold && /production/pallets-management && /production/palletsqrcodes

export const EXPORTS = 'exports'; // /exports
export const DATA_PORTAL = 'data_portal'; // /dataportal

export const SURVEY_VIEW = 'survey_view'; // /surveys/:pubkey

export const TEAMMATES_LIST = 'teammates_list'; // /teammates
export const TEAMMATES_DETAIL = 'teammates_detail'; // /teammates/:pubkey
export const TEAMMATES_EDIT = 'teammates_edit'; // /teammates/:pubkey

export const FAST_CONSULTATION = 'fast_consultation'; // /fastconsultation
export const HELP = 'help'; // /help
export const HELP_STATUS_VIEWER = 'help_status_viewer'; // /help/statuses
export const HELP_BOX_OBJECT_VIEWER = 'help_box_object_viewer'; // /help/box-objects
export const HELP_REVIEW_AND_REFINE_VIEWER = 'help_review_and_refine_viewer'; // /help/review-and-refine

export const SALON_LIST = 'salon_list';
export const SALON_VIEW = 'salon_view';
