import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import flatMap from 'lodash/fp/flatMap';
import flatten from 'lodash/fp/flatten';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import overSome from 'lodash/fp/overSome';
import some from 'lodash/fp/some';

import { createSelector } from 'reselect';

import orderFreeReasons from '../../Apps/Customers/constants/reasonsFreeOrder';

import { getItemName } from '../../utils/itemsHelper';

import { capitalize, humanize } from '../../helpers';

const getOrdersState = state => state.orders;

export const getOrders = createSelector(getOrdersState, get('orders'));
export const getLoading = createSelector(getOrdersState, get('loading'));
export const getPages = createSelector(getOrdersState, get('pages'));
export const getError = createSelector(getOrdersState, get('error'));

export const getOrderByPubkey = createSelector(
  getOrders,
  (state, props) => props.orderPubkey,
  (orders, pubkey) => find({ pubkey }, orders)
);

export const getSerialNo = createSelector(getOrderByPubkey, get('serial_no'));
export const getTotalShipping = createSelector(getOrderByPubkey, get('total_shipping'));
export const getCurrency = createSelector(getOrderByPubkey, get('currency'));
export const getTotalPrice = createSelector(getOrderByPubkey, get('total_price'));
export const getTotalTaxes = createSelector(getOrderByPubkey, get('total_taxes'));
export const getTotalCredit = createSelector(getOrderByPubkey, get('total_credit'));
export const getTaxPercent = createSelector(
  getOrderByPubkey,
  order => get('tax_rate', order) * 100 // Not a per-cent-age but a per-one-age so need to multiply by 100
);

export const getOrderItems = createSelector(getOrderByPubkey, get('items'));

export const isRefundableByProduct = createSelector(
  getOrderByPubkey,
  get('allow_refund_by_product')
); // Boolean

export const getNonRefundableItemsFromCoupons = createSelector(
  getOrderByPubkey,
  flow(
    get('coupon_customers'),
    filter({ coupon: { coupon_type: 'free_products' } }),
    map(get('coupon.products')),
    flatten
  )
);

export const getShippingAddress = createSelector(getOrderByPubkey, get('shipping_address'));
export const getShippingCountry = createSelector(getShippingAddress, get('country'));
export const getIsCanadian = createSelector(getShippingCountry, country => country === 'CA');
export const getIsUk = createSelector(getShippingCountry, country => country === 'UK');
export const getIsUs = createSelector(getShippingCountry, country => country === 'US');
export const getIsForeign = createSelector(getShippingCountry, country => country !== 'US');
export const getTags = createSelector(getOrderByPubkey, get('tags')); // [urban_cowboy, has_defect]
export const getCreatedAt = createSelector(getOrderByPubkey, get('created_at'));
export const getCustomer = createSelector(getOrderByPubkey, get('customer'));
export const getCustomerUserTags = createSelector(getCustomer, get('tags'));
export const getCustomerUserTagSlugs = createSelector(getCustomerUserTags, map(get('slug')));
export const getCustomerIsVip = createSelector(getCustomerUserTagSlugs, includes('vip'));
export const getCustomerIsInfluencer = createSelector(
  getCustomerUserTagSlugs,
  includes('influencer')
);

export const getIsCustomerFirstOrder = createSelector(
  getOrderByPubkey,
  get('customer_first_order')
);
export const getFormulaFeedbacksGeneratedFrom = createSelector(
  getOrderByPubkey,
  get('formula_feedbacks_generated_from')
);
export const getGiftReason = createSelector(getOrderByPubkey, get('gift_reason'));
export const getGifter = createSelector(getOrderByPubkey, get('gifter'));
export const getGifterFullName = createSelector(getOrderByPubkey, get('gifter_fullname'));
export const getIsFromGift = createSelector(getOrderByPubkey, get('is_from_gift'));
export const getIsGift = createSelector(getOrderByPubkey, get('is_gift'));
export const getIsGiftBrush = createSelector(getOrderByPubkey, get('is_gift_brush'));
export const getIsSubscription = createSelector(getOrderByPubkey, get('is_subscription'));
export const getPriceExperimentVariant = createSelector(
  getOrderByPubkey,
  get('price_experiment_variant')
);
export const getStatus = createSelector(getOrderByPubkey, get('status'));
export const getStatuses = createSelector(getOrderByPubkey, get('statuses'));
export const getSubscriptionPubkey = createSelector(getOrderByPubkey, get('subscription_pubkey'));
export const getFreeOrderReason = createSelector(getOrderByPubkey, get('free_order_reason'));
export const getFreeOrderReasonLabel = createSelector(getFreeOrderReason, reason =>
  get('label', find({ value: reason }, orderFreeReasons))
);

const getOrderBoxes = createSelector(getOrderByPubkey, get('boxes'));
const getOrderBoxesWithoutCoreProducts = createSelector(
  getOrderBoxes,
  filter({ contains_core_products: false })
);

export const getNonShippableItems = createSelector(
  getOrderItems,
  filter({ variant: { is_shippable: false } })
);

export const getItemsWithoutCoreProductsInBox = createSelector(
  [getOrderBoxesWithoutCoreProducts, getOrderItems],
  (boxes, items) => {
    if (isEmpty(boxes)) return null;
    return flow(
      flatMap(box => map(item => find({ pubkey: item.pubkey }, items), box.items)),
      filter(item => !item.variant.is_bulky)
    )(boxes);
  }
);

export const getRefunds = createSelector(getOrderByPubkey, get('refunds'));

const getRefundedItems = createSelector(getRefunds, flow(map('refund_items'), flatten));

const getCanceledOrderItemsPubkeys = createSelector(
  getRefundedItems,
  flow(filter({ status: 'canceled', is_orphan: true }), map('order_item'))
);

export const getCanceledItems = createSelector(
  [getCanceledOrderItemsPubkeys, getOrderItems],
  (canceledOrderItemsPubkeys, items) =>
    map(
      canceledOrderItemsPubkey => find({ pubkey: canceledOrderItemsPubkey }, items),
      canceledOrderItemsPubkeys
    )
); // For Type "ITEMS" Array of canceled items

export const getDispatchableItems = createSelector(
  [getOrderItems, getCanceledItems],
  (orderItems, canceledItems) =>
    flow(
      filter(
        overSome([
          { variant: { is_formula: true } },
          { variant: { is_producible: true } },
          { variant: { is_bulky: true } },
        ])
      ),
      filter(item => !includes(item, canceledItems))
    )(orderItems)
); // needed for supplements and brushes);

export const getFormula = createSelector(getOrdersState, get('formula'));

export const getOrderGift = createSelector(getOrderByPubkey, order => {
  if (!order) return null;

  return find({ variant: { product: { type: 'digital_gift' } } }, order?.items);
});

export const hasBrush = createSelector(getOrderByPubkey, order => {
  if (!order) return null;

  return Boolean(some({ variant: { product: { type: 'brush' } } }, order?.items));
});

export const hasFormulas = createSelector(getOrderByPubkey, order => {
  if (!order) return null;

  return Boolean(find({ variant: { is_formula: true } }, order?.items));
});

export const hasGift = createSelector(getOrderGift, gift => Boolean(gift));

export const hasProducibles = createSelector(getOrderByPubkey, order => {
  if (!order) return null;

  return Boolean(find({ variant: { is_producible: true } }, order?.items));
});

export const hasBulky = createSelector(getOrderByPubkey, order => {
  if (!order) return null;

  return Boolean(find({ variant: { is_bulky: true } }, order?.items));
});

// hack to identify the percentage of the subscription discount that was applied to the order
export const getSubscriptionDiscount = createSelector(getOrderByPubkey, order => {
  const subscriptionCouponCustomer = flow(
    get('coupon_customers'),
    find({ coupon: { coupon_property: 'subscription' } })
  )(order);

  return subscriptionCouponCustomer?.coupon?.amount || 0.15;
});

export const getHasGiftCard = createSelector(
  getOrderByPubkey,
  flow(get('coupon_customers'), some({ coupon: { origin: 'gift' } }))
);

export const getGiftCardPubkey = createSelector(
  getOrderByPubkey,
  flow(get('coupon_customers'), find({ coupon: { origin: 'gift' } }), get('coupon'), get('gift'))
);

export const getFormattedNonShippableItemsDetails = createSelector(getNonShippableItems, items =>
  map(
    item => ({
      pubkey: item?.pubkey,
      type: {
        itemType: item?.variant.product.type,
        itemObject: null,
      },
      kind: capitalize(humanize(getItemName(item))),
      status: '',
      quantity: 1,
      price: {
        currency: null,
        amount: item?.unit_price,
      },
      subscription: null,
      total: {
        currency: null,
        isSubscription: item?.is_subscription,
        qty: item?.quantity,
        unitPrice: item?.unit_price,
      },
      modal: null,
      isProducible: item?.variant?.is_producible,
    }),
    items
  )
);
